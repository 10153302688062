import { useContext, useEffect, useState } from "react";
import { GeralContext } from "../../../../contexts/Geral/GeralContext";
import { Link, useLocation } from "react-router-dom";
import MessageBox from "../../../../components/Utils/MessageBox";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { AuthContext } from "../../../../contexts/Auth/AuthContext";
import { Typography } from "@material-tailwind/react";
import { PessoaContext } from "../../../../contexts/Pessoa/PessoaContext";

interface Turnos {
  manha: {
    id_terap: string;
    id_monitor: string;
    id_ativ: string;
  };
  tarde: {
    id_terap: string;
    id_monitor: string;
    id_ativ: string;
  };
}

interface DiasDaSemana {
  [key: string]: Turnos;
}

interface SalaItem {
  id_sala: string;
  n_sala: string;
  turnos: DiasDaSemana;
  // outras propriedades se houver
}

interface TerapeutaItem {
  id_terap: string;
  nome: string;
  especialidade: string;
}

interface AtividadeItem {
  id_ativ: string;
  atividade: string;
}

interface MonitorItem {
  id_monitor: string;
  nome: string;
  especialidade: string;
}

export const EditaSala = () => {
  const { salaActions, atividadeActions } = useContext(GeralContext);
  const { terapActions, monitorActions } = useContext(PessoaContext);
  const [nSala, setNSala] = useState<string | undefined>();
  const [idSala, setIdSala] = useState<string>('');
  const [tableData, setTableData] = useState<TerapeutaItem[]>([]);
  const [tableDataAtiv, setTableDataAtiv] = useState<AtividadeItem[]>([]);
  const [tableDataMonitor, setTableDataMonitor] = useState<MonitorItem[]>([]);
  const [turnos, setTurnos] = useState<DiasDaSemana>({
    "Segunda-Feira": { manha: { id_terap: '', id_monitor: '', id_ativ: '' }, tarde: { id_terap: '', id_monitor: '', id_ativ: '' } },
    "Terça-Feira": { manha: { id_terap: '', id_monitor: '', id_ativ: '' }, tarde: { id_terap: '', id_monitor: '', id_ativ: '' } },
    "Quarta-Feira": { manha: { id_terap: '', id_monitor: '', id_ativ: '' }, tarde: { id_terap: '', id_monitor: '', id_ativ: '' } },
    "Quinta-Feira": { manha: { id_terap: '', id_monitor: '', id_ativ: '' }, tarde: { id_terap: '', id_monitor: '', id_ativ: '' } },
    "Sexta-Feira": { manha: { id_terap: '', id_monitor: '', id_ativ: '' }, tarde: { id_terap: '', id_monitor: '', id_ativ: '' } },
    Sábado: { manha: { id_terap: '', id_monitor: '', id_ativ: '' }, tarde: { id_terap: '', id_monitor: '', id_ativ: '' } },
    Domingo: { manha: { id_terap: '', id_monitor: '', id_ativ: '' }, tarde: { id_terap: '', id_monitor: '', id_ativ: '' } },
  });
  const [message, setMessage] = useState<string>('');
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [showIcon, setShowIcon] = useState<boolean>(false);
  const location = useLocation();
  const selectedItem = location.state?.selectedItem as SalaItem | undefined; // Assegurar que selectedItem seja do tipo SalaItem

  const auth = useContext(AuthContext);
  const idUser = auth.user?.id_user;

  const handleTurnoChange = (dia: string, turno: 'manha' | 'tarde', key: 'id_terap' | 'id_monitor' | 'id_ativ', value: string) => {
    setTurnos(prevTurnos => ({
      ...prevTurnos,
      [dia]: {
        ...prevTurnos[dia],
        [turno]: {
          ...prevTurnos[dia][turno],
          [key]: value
        }
      }
    }));
  };

  const handleSubmit = async () => {
    if (nSala && idUser) {
      const turnosFormatados: DiasDaSemana = {};

      for (const [dia, turnosDia] of Object.entries(turnos)) {
        turnosFormatados[dia] = {
          manha: {
            id_terap: turnosDia.manha.id_terap || '',
            id_monitor: turnosDia.manha.id_monitor || '',
            id_ativ: turnosDia.manha.id_ativ || ''
          },
          tarde: {
            id_terap: turnosDia.tarde.id_terap || '',
            id_monitor: turnosDia.tarde.id_monitor || '',
            id_ativ: turnosDia.tarde.id_ativ || ''
          }
        };
      }

      try {
        const ItemUpdated = await salaActions.edit({
          id_sala: idSala,
          n_sala: nSala,
          id_user: idUser,
          turnos: turnosFormatados,
        });

        if (ItemUpdated) {
          setMessage('Dados atualizados com sucesso');
        } else {
          setMessage("Não foi possível atualizar os dados.");
          setShowIcon(true);
        }
      } catch (error) {
        console.error("Erro ao atualizar os dados:", error);
        setMessage("Ocorreu um erro ao atualizar os dados.");
        setShowIcon(true);
      }
    } else {
      setMessage("Por favor, preencha todos os campos.");
      setShowIcon(true);
    }
    setShowMessage(true);
  };

  useEffect(() => {
    if (selectedItem) {
      setIdSala(selectedItem.id_sala);
      setNSala(selectedItem.n_sala);

      const turnosFormatados: DiasDaSemana = {
        "Segunda-Feira": { manha: { id_terap: '', id_monitor: '', id_ativ: '' }, tarde: { id_terap: '', id_monitor: '', id_ativ: '' } },
        "Terça-Feira": { manha: { id_terap: '', id_monitor: '', id_ativ: '' }, tarde: { id_terap: '', id_monitor: '', id_ativ: '' } },
        "Quarta-Feira": { manha: { id_terap: '', id_monitor: '', id_ativ: '' }, tarde: { id_terap: '', id_monitor: '', id_ativ: '' } },
        "Quinta-Feira": { manha: { id_terap: '', id_monitor: '', id_ativ: '' }, tarde: { id_terap: '', id_monitor: '', id_ativ: '' } },
        "Sexta-Feira": { manha: { id_terap: '', id_monitor: '', id_ativ: '' }, tarde: { id_terap: '', id_monitor: '', id_ativ: '' } },
        Sábado: { manha: { id_terap: '', id_monitor: '', id_ativ: '' }, tarde: { id_terap: '', id_monitor: '', id_ativ: '' } },
        Domingo: { manha: { id_terap: '', id_monitor: '', id_ativ: '' }, tarde: { id_terap: '', id_monitor: '', id_ativ: '' } },
      };
      console.log(selectedItem.turnos);
      if (selectedItem.turnos) {
        Object.entries(selectedItem.turnos).forEach(([dia, periodos]) => {
          if (typeof periodos === 'object' && periodos !== null) {
            turnosFormatados[dia] = {
              manha: {
                id_terap: periodos.manha?.id_terap || '',
                id_monitor: periodos.manha?.id_monitor || '',
                id_ativ: periodos.manha?.id_ativ || ''
              },
              tarde: {
                id_terap: periodos.tarde?.id_terap || '',
                id_monitor: periodos.tarde?.id_monitor || '',
                id_ativ: periodos.tarde?.id_ativ || ''
              }
            };
          }
        });
      }

      setTurnos(turnosFormatados);
      console.log(turnosFormatados);
    }
  }, [selectedItem]);

  useEffect(() => {
    const fetchItemData = async () => {
      try {
        const terapData = await terapActions.fill();
        setTableData(terapData);
        const ativData = await atividadeActions.fill();
        setTableDataAtiv(ativData);
        const monitorData = await monitorActions.fill();
        setTableDataMonitor(monitorData);
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
      }
    };
    fetchItemData();
  }, [terapActions, atividadeActions, monitorActions]);

  const handleCloseMessage = () => {
    setShowMessage(false);
    if (!showIcon) {
      window.location.href = '/sala';
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center pt-12">
      {showMessage && (
        <MessageBox
          message={message}
          onClose={() => {
            setShowIcon(false);
            handleCloseMessage();
          }}
          icon={showIcon ? (
            <ExclamationCircleIcon className="w-10 h-10 mx-auto text-red-500" />
          ) : null}
        />
      )}
      <form className="w-full max-w-7xl">
        <div className="space-y-12">
          <div className="border-b border-gray-900/10 pb-12">
            <h2 className="text-3xl font-bold leading-8 text-gray-900 border-b-4 border-indigo-500 pb-2">
              Editar Sala
            </h2>
            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="col-span-full">
                <label htmlFor="nsala" className="block text-sm font-medium leading-6 text-gray-900">
                  N. Sala
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="nsala"
                    id="nsala"
                    autoComplete="given-name"
                    value={nSala || ''}
                    onChange={(e) => setNSala(e.target.value)}
                    className="block w-full max-w-xs mx-auto rounded-md border-0 py-1.5 text-center text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="col-span-full border-4 p-3">
                <label htmlFor="turno" className="block text-sm font-medium leading-6 text-gray-900">
                  Turno
                </label>
                <div className="mt-2">
                  {['Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado', 'Domingo'].map(dia => (
                    <div key={dia} className="mb-6">
                      <Typography placeholder='' variant="small" color="blue-gray" className="font-normal py-2">
                        <strong>{dia}:</strong>
                      </Typography>
                      <div className="flex flex-col w-full">
                        {/* Manhã */}
                        <div className="flex items-center mb-4">
                          <div className="flex-shrink-0 w-20 text-gray-900 text-center">
                            <span className="font-medium">Manhã:</span>
                          </div>
                          <div className="flex-1">
                            <div className="grid grid-cols-3 gap-x-4">
                              <div>
                                <label className="block text-sm font-medium leading-6 text-gray-900">Atividade</label>
                                <select
                                  value={turnos[dia]?.manha.id_ativ || ''}
                                  onChange={(e) => handleTurnoChange(dia, 'manha', 'id_ativ', e.target.value)}
                                  className="block w-full rounded-md border-0 py-1.5 text-center text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                >
                                  <option value="">Selecione uma Atividade</option>
                                  {tableDataAtiv.map((item) => (
                                    <option key={item.id_ativ} value={item.id_ativ}>{item.atividade}</option>
                                  ))}
                                </select>
                              </div>
                              <div>
                                <label className="block text-sm font-medium leading-6 text-gray-900">Terapeuta</label>
                                <select
                                  value={turnos[dia]?.manha.id_terap || ''}
                                  onChange={(e) => handleTurnoChange(dia, 'manha', 'id_terap', e.target.value)}
                                  className="block w-full rounded-md border-0 py-1.5 text-center text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                >
                                  <option value="">Selecione um(a) Terapeuta</option>
                                  {tableData.map((item) => (
                                    <option key={item.id_terap} value={item.id_terap}>{item.nome}</option>
                                  ))}
                                </select>
                              </div>
                              <div>
                                <label className="block text-sm font-medium leading-6 text-gray-900">Auxiliar</label>
                                <select
                                  value={turnos[dia]?.manha.id_monitor || ''}
                                  onChange={(e) => handleTurnoChange(dia, 'manha', 'id_monitor', e.target.value)}
                                  className="block w-full rounded-md border-0 py-1.5 text-center text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                >
                                  <option value="">Selecione um(a) Auxilar</option>
                                  {tableDataMonitor.map((item) => (
                                    <option key={item.id_monitor} value={item.id_monitor}>{item.nome}</option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Tarde */}
                        <div className="flex items-center">
                          <div className="flex-shrink-0 w-20 text-gray-900 text-center">
                            <span className="font-medium">Tarde:</span>
                          </div>
                          <div className="flex-1">
                            <div className="grid grid-cols-3 gap-x-4">
                              <div>
                                <label className="block text-sm font-medium leading-6 text-gray-900">Atividade</label>
                                <select
                                  value={turnos[dia]?.tarde.id_ativ || ''}
                                  onChange={(e) => handleTurnoChange(dia, 'tarde', 'id_ativ', e.target.value)}
                                  className="block w-full rounded-md border-0 py-1.5 text-center text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                >
                                  <option value="">Selecione uma Atividade</option>
                                  {tableDataAtiv.map((item) => (
                                    <option key={item.id_ativ} value={item.id_ativ}>{item.atividade}</option>
                                  ))}
                                </select>
                              </div>
                              <div>
                                <label className="block text-sm font-medium leading-6 text-gray-900">Terapeuta</label>
                                <select
                                  value={turnos[dia]?.tarde.id_terap || ''}
                                  onChange={(e) => handleTurnoChange(dia, 'tarde', 'id_terap', e.target.value)}
                                  className="block w-full rounded-md border-0 py-1.5 text-center text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                >
                                  <option value="">Selecione um(a) Terapeuta</option>
                                  {tableData.map((item) => (
                                    <option key={item.id_terap} value={item.id_terap}>{item.nome}</option>
                                  ))}
                                </select>
                              </div>
                              <div>
                                <label className="block text-sm font-medium leading-6 text-gray-900">Auxiliar</label>
                                <select
                                  value={turnos[dia]?.tarde.id_monitor || ''}
                                  onChange={(e) => handleTurnoChange(dia, 'tarde', 'id_monitor', e.target.value)}
                                  className="block w-full rounded-md border-0 py-1.5 text-center text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                >
                                  <option value="">Selecione um(a) Auxiliar</option>
                                  {tableDataMonitor.map((item) => (
                                    <option key={item.id_monitor} value={item.id_monitor}>{item.nome}</option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr className="my-4" />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="mt-6 flex items-center justify-end gap-x-6 pb-12">
              <Link to="/sala" className="text-sm font-semibold leading-6 text-gray-900">
                Voltar
              </Link>
              <button
                type="button"
                onClick={handleSubmit}
                className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Atualizar
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
  
};
