export const Footer = () => {
    return (
        <div>
            <footer className="bg-gradient-to-r from-yellow-500 via-green-500 to-blue-500 py-4">
            <div className="container mx-auto text-center">
            <p>&copy; 2024. Todos os direitos reservados.</p>
            </div>
            </footer>
        </div>
    );
  }