import { useContext, useEffect, useState } from "react";
import { GeralContext } from "../../../../contexts/Geral/GeralContext";
import { Link, useLocation } from "react-router-dom";
import MessageBox from "../../../../components/Utils/MessageBox";
import { ExclamationCircleIcon, PrinterIcon } from "@heroicons/react/24/outline";
import { PessoaContext } from "../../../../contexts/Pessoa/PessoaContext";
import logo from "../../../../images/logo/logo.png";
import { AuthContext } from "../../../../contexts/Auth/AuthContext";
import Modal from 'react-modal'; // Importe o componente Modal
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

// Importe tailwindcss
import "tailwindcss/tailwind.css";
import { Anamnese } from "../../../../components/Utils/Anamnese";
import { Evolucao } from "../../../../components/Utils/Evolucao";


interface Responsavel {
  nome: string;
  filiacao: string;
  telefone: string;
}

interface PacienteItem {
  seq_pac: string;
  id_pac: string;
  nome: string;
  dt_nasc: string;
  genero: string;
  historico: HistoricoMed[];
  responsaveis:Responsavel[]; 
  codconvenio: string;
  convenio: string;
  periodo: string;
  dias: number;
  sessoes: number;
  cep: string;
  estado: string;
  cidade: string;
  bairro: string;
  logradouro: string;
  numeral: string;
  complemento: string;
  telefone: string;
  terap_resp: string;
}

interface HistoricoMed {
  id_pac: string;
  historico_medico: string;
  alergias: string;
  medicamentos_em_uso: string;
  motivo: string;
  historico_procedimentos: string;
  nome_terap: string;
  id_terap: string;
  dt_insert: string;
} 

interface FieldVisibility {
  [key: string]: boolean;
}

type FieldNames = {
  [key in keyof FieldVisibility]: string;
};

interface CidItem {
  cid: string;
  diagnostico: string;
}

export const Prontuario = () => {
  const {historicomedActions, pacActions } = useContext(PessoaContext);
  const {cidActions} = useContext(GeralContext);
  const [idPac, setIdPac] = useState<string>();
  const [pac, setPac] = useState<PacienteItem[]>([]);
  const [message, setMessage] = useState<string>("");
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [showIcon, setShowIcon] = useState<boolean>(false);
  const [historicoMedico, setHistoricoMedico] = useState<string>("");
  const [alergias, setAlergias] = useState<string>('');
  const [medicamentosEmUso, setMedicamentosEmUso] = useState<string>('');
  const [motivo, setMotivo] = useState<string>('');
  const [historicoProcedimentos, setHistoricoProcedimentos] = useState<string>("");
  const [currentDateTime, setCurrentDateTime] = useState(getCurrentDateTime());
  const [modalHistoricoIsOpen, setHistoricoModalIsOpen] = useState<boolean>(false); // Estado para controlar se o Modal está aberto
  const [modalEvolucaoIsOpen, setEvolucaoModalIsOpen] = useState<boolean>(false); // Estado para controlar se o Modal está aberto
  const location = useLocation();
  const auth = useContext(AuthContext);
  const setSelectedId = location.state?.setSelectedId;
  const idUser = String(auth.user?.id_user);
  const nome = auth.user?.nome;
  const ocupacao = auth.user?.ocupacao;
  const IsAdministrador = auth.user?.level === 4;
  const IsSupervisor = auth.user?.supervisor;
  const IsTerapeuta = auth.user?.level === 3;
  const IsMonitor = auth.user?.level === 2;
  const IsEstagiario = auth.user?.level === 1;
  const IsAdmin = auth.user?.admin;

  const [cids, setCids] = useState<CidItem[]>([]);
  const [cidInput, setCidInput] = useState("");
  const [diagnosticoInput, setDiagnosticoInput] = useState("");
  const [diagnosticoSelecionado, setDiagnosticoSelecionado] = useState("");
  const [showOptions, setShowOptions] = useState(false);
  const [cidsFiltrados, setCidsFiltrados] = useState<CidItem[]>([]);

  const [cidFisioInput, setCidFisioInput] = useState("");
  const [diagnosticoFisioInput, setDiagnosticoFisioInput] = useState("");
  const [diagnosticoFisioSelecionado, setDiagnosticoFisioSelecionado] = useState("");
  const [showOptionsFisio, setShowOptionsFisio] = useState(false);
  const [cidsFisioFiltrados, setCidsFisioFiltrados] = useState<CidItem[]>([]);

  const [cidFonoInput, setCidFonoInput] = useState("");
  const [diagnosticoFonoInput, setDiagnosticoFonoInput] = useState("");
  const [diagnosticoFonoSelecionado, setDiagnosticoFonoSelecionado] = useState("");
  const [showOptionsFono, setShowOptionsFono] = useState(false);
  const [cidsFonoFiltrados, setCidsFonoFiltrados] = useState<CidItem[]>([]);

  const [cidPsicoInput, setCidPsicoInput] = useState("");
  const [diagnosticoPsicoInput, setDiagnosticoPsicoInput] = useState("");
  const [diagnosticoPsicoSelecionado, setDiagnosticoPsicoSelecionado] = useState("");
  const [showOptionsPsico, setShowOptionsPsico] = useState(false);
  const [cidsPsicoFiltrados, setCidsPsicoFiltrados] = useState<CidItem[]>([]);

  const fieldNames: FieldNames = {
    historicoMedico: 'Histórico Médico',
    alergias: 'Alergias',
    medicamentosEmUso: 'Medicamentos em Uso',
    motivo: 'Motivo',
    historicoProcedimentos: 'Inserir Procedimentos / Evolução',
    cidInput: 'CID Geral',
    cidFisioInput: 'CID Fisio',
    cidFonoInput: 'CID Fono',
    cidPsicoInput: 'CID Psico',
  };

  const [showFields, setShowFields] = useState<FieldVisibility>(
    Object.keys(fieldNames).reduce((acc, fieldName) => {
      acc[fieldName] = false;
      return acc;
    }, {} as FieldVisibility)
  );

  const toggleFieldVisibility = (fieldName: keyof FieldVisibility): void => {
    setShowFields((prevFields) => ({
      ...prevFields,
      [fieldName]: !prevFields[fieldName],
    }));
  };

  useEffect(() => {
    const fetchCidData = async () => {
      try {
        const cidData = await cidActions.fill();
        setCids(cidData);
      } catch (error) {
        console.error("Erro ao buscar dados dos CIDs:", error);
      }
    };
    fetchCidData();
  }, [cidActions]);

  useEffect(() => {
    if (setSelectedId !== undefined) {
      setIdPac(setSelectedId);
    }
  }, [setSelectedId]);

  useEffect(() => {
    const fetchPacData = async () => {
      try {
        const fetchPacData = await pacActions.fill(idPac);
        setPac(fetchPacData);
        console.log(fetchPacData);
      } catch (error) {
        console.error("Erro ao buscar dados das formas de trasnferências:", error);
      }
    };
    fetchPacData();
  }, [pacActions, idPac]);

  const handleSubmit = async () => {
  
    if (idPac) {
      const HistMedAltered = await historicomedActions.add({
        id_pac: idPac,
        id_terap: idUser,
        historico_medico: historicoMedico,
        alergias: alergias,
        medicamentos_em_uso: medicamentosEmUso,
        motivo: motivo,
        historico_procedimentos: historicoProcedimentos
      });

      const PacAltered = await pacActions.edit({
        id_pac:idPac,
        cid_geral: cidInput,
        cid_fisio: cidFisioInput,
        cid_fono: cidFonoInput,
        cid_psico: cidPsicoInput,
        id_user: idUser
      });
      
      if (HistMedAltered || PacAltered) {
        setMessage('Dados atualizados com sucesso');
      } else {
        setMessage("Não foi possível inserir os dados.");
        setShowIcon(true);
      }
    }
  
    setShowMessage(true);
  };

  const handleCloseMessage = () => {
    setShowMessage(false);
    if (!showIcon) {
      window.location.href = './prontuario'; // Redireciona apenas se não houver ícone de erro
    }
  };

  const calculateAgeInDays = (birthdate: string): number => {
    const today = new Date();
    const birthDate = new Date(birthdate);
    const diffTime = Math.abs(today.getTime() - birthDate.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  const calculateAge = (birthdate: string) => {
    const ageInDays = calculateAgeInDays(birthdate);
    let years = Math.floor(ageInDays / 365);
    let months = Math.floor((ageInDays % 365) / 30);
    let days = (ageInDays % 365) % 30;

    return { years, months, days };
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentDateTime(getCurrentDateTime());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  function getCurrentDateTime() {
    const currentDateTime = new Date();
    const day = currentDateTime.getDate();
    const month = currentDateTime.getMonth() + 1;
    const year = currentDateTime.getFullYear();
    const hour = currentDateTime.getHours().toString().padStart(2, '0');
    const minute = currentDateTime.getMinutes().toString().padStart(2, '0');
    const second = currentDateTime.getSeconds().toString().padStart(2, '0');
    return `${day}/${month}/${year} ${hour}:${minute}:${second}`;
  }

  const handleDiagnosticoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setDiagnosticoInput(value);
    // Filtrar os CIDs com base no diagnóstico digitado
    const cidsFiltrados = cids.filter(cid =>
      cid.diagnostico.toLowerCase().includes(value.toLowerCase())
    );
    setCidsFiltrados(cidsFiltrados);
    setShowOptions(true); // Defina showOptions como true ao digitar no campo de diagnóstico
  };

  const handleCidSelect = (cid: string, diagnostico: string) => {
    setDiagnosticoSelecionado(`${cid} - ${diagnostico}`);
    setDiagnosticoInput(`${cid} - ${diagnostico}`);
    setCidInput(cid);
    setShowOptions(false); // Fechar as opções ao selecionar um diagnóstico
  };
  
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    console.log(event.key);
    if (event.key === "Backspace" || event.key === "Delete") {
      // Verificar se há uma opção selecionada
      if (diagnosticoSelecionado === "") {
        // Se houver uma opção selecionada, limpar apenas o campo de texto
        setDiagnosticoSelecionado("");
      } else {
        // Se não houver uma opção selecionada, limpar todos os campos
        setDiagnosticoSelecionado("");
        setDiagnosticoInput("");
        setCidInput("");
        setShowOptions(false);
      }
    }
  };

  const handleDiagnosticoFisioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setDiagnosticoFisioInput(value);
    // Filtrar os CIDs com base no diagnóstico digitado
    const cidsFisioFiltrados = cids.filter(cid =>
      cid.diagnostico.toLowerCase().includes(value.toLowerCase())
    );
    setCidsFisioFiltrados(cidsFisioFiltrados);
    setShowOptionsFisio(true); // Defina showOptions como true ao digitar no campo de diagnóstico
  };

  const handleCidFisioSelect = (cid: string, diagnostico: string) => {
    setDiagnosticoFisioSelecionado(`${cid} - ${diagnostico}`);
    setDiagnosticoFisioInput(`${cid} - ${diagnostico}`);
    setCidFisioInput(cid);
    setShowOptionsFisio(false); // Fechar as opções ao selecionar um diagnóstico
  };

  const handleDiagnosticoFonoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setDiagnosticoFonoInput(value);
    // Filtrar os CIDs com base no diagnóstico digitado
    const cidsFonoFiltrados = cids.filter(cid =>
      cid.diagnostico.toLowerCase().includes(value.toLowerCase())
    );
    setCidsFonoFiltrados(cidsFonoFiltrados);
    setShowOptionsFono(true); // Defina showOptions como true ao digitar no campo de diagnóstico
  };

  const handleCidFonoSelect = (cid: string, diagnostico: string) => {
    setDiagnosticoFonoSelecionado(`${cid} - ${diagnostico}`);
    setDiagnosticoFonoInput(`${cid} - ${diagnostico}`);
    setCidFonoInput(cid);
    setShowOptionsFono(false); // Fechar as opções ao selecionar um diagnóstico
  };

  const handleKeyDownFono = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Backspace" || event.key === "Delete") {
      // Verificar se há uma opção selecionada
      if (diagnosticoFonoSelecionado === "") {
        // Se houver uma opção selecionada, limpar apenas o campo de texto
        setDiagnosticoFonoSelecionado("");
      } else {
        // Se não houver uma opção selecionada, limpar todos os campos
        setDiagnosticoFonoSelecionado("");
        setDiagnosticoFonoInput("");
        setCidFonoInput("");
        setShowOptionsFono(false);
      }
    }
  };

  const handleDiagnosticoPsicoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setDiagnosticoPsicoInput(value);
    // Filtrar os CIDs com base no diagnóstico digitado
    const cidsPsicoFiltrados = cids.filter(cid =>
      cid.diagnostico.toLowerCase().includes(value.toLowerCase())
    );
    setCidsPsicoFiltrados(cidsPsicoFiltrados);
    setShowOptionsPsico(true); // Defina showOptions como true ao digitar no campo de diagnóstico
  };

  const handleCidPsicoSelect = (cid: string, diagnostico: string) => {
    setDiagnosticoPsicoSelecionado(`${cid} - ${diagnostico}`);
    setDiagnosticoPsicoInput(`${cid} - ${diagnostico}`);
    setCidPsicoInput(cid);
    setShowOptionsPsico(false); // Fechar as opções ao selecionar um diagnóstico
  };

  const handleKeyDownPsico = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Backspace" || event.key === "Delete") {
      // Verificar se há uma opção selecionada
      if (diagnosticoPsicoSelecionado === "") {
        // Se houver uma opção selecionada, limpar apenas o campo de texto
        setDiagnosticoPsicoSelecionado("");
      } else {
        // Se não houver uma opção selecionada, limpar todos os campos
        setDiagnosticoPsicoSelecionado("");
        setDiagnosticoPsicoInput("");
        setCidPsicoInput("");
        setShowOptionsPsico(false);
      }
    }
  };

  const handleKeyDownFisio = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Backspace" || event.key === "Delete") {
      // Verificar se há uma opção selecionada
      if (diagnosticoFisioSelecionado === "") {
        // Se houver uma opção selecionada, limpar apenas o campo de texto
        setDiagnosticoFisioSelecionado("");
      } else {
        // Se não houver uma opção selecionada, limpar todos os campos
        setDiagnosticoFisioSelecionado("");
        setDiagnosticoFisioInput("");
        setCidFisioInput("");
        setShowOptionsFisio(false);
      }
    }
  };

  const handleClickPrintAnamnese = async () => {
    const idPaciente = idPac; // Substitua pelo ID real do paciente
    if(idPaciente && idUser && ocupacao){
      const impressao = new Anamnese(idPaciente, pacActions, idUser, nome, ocupacao); // Substitua pacActions pelo nome correto do objeto com as ações do paciente
      try {
        const documento = await impressao.PreparaDocumento();
        const blob = new Blob([documento], { type: "application/pdf" });
        const url = URL.createObjectURL(blob);
        window.open(url, "_blank");
      } catch (error) {
        console.error("Erro ao preparar o documento:", error);
      }
    }
  };

  const handleClickPrintEvolucao = async () => {
    const idPaciente = idPac; // Substitua pelo ID real do paciente
    if(idPaciente && idUser && ocupacao){
      const impressao = new Evolucao(idPaciente, pacActions, idUser, nome, ocupacao); // Substitua pacActions pelo nome correto do objeto com as ações do paciente
      try {
        const documento = await impressao.PreparaDocumento();
        const blob = new Blob([documento], { type: "application/pdf" });
        const url = URL.createObjectURL(blob);
        window.open(url, "_blank");
      } catch (error) {
        console.error("Erro ao preparar o documento:", error);
      }
    }
  };

  return (
    <div className="p-12 bg-gray-100">
      <div className="relative max-w-4xl mx-auto p-8 bg-white">
        <div className="mb-12">
        <div className="flex justify-between items-center m-6">
          <div>
          {(IsAdministrador  || IsTerapeuta) && <button
            onClick={() => setHistoricoModalIsOpen(true)}
            className="mr-6 bg-green-600 hover:bg-green-700 text-white px-6 py-2 rounded-md focus:outline-none focus-visible:ring focus-visible:ring-green-500 focus-visible:ring-opacity-50"
          >
            Histórico
          </button>}
          {(IsAdministrador  || IsTerapeuta) && <button
            onClick={() => setEvolucaoModalIsOpen(true)}
            className="bg-green-600 hover:bg-green-700 text-white px-6 py-2 rounded-md focus:outline-none focus-visible:ring focus-visible:ring-green-500 focus-visible:ring-opacity-50"
          >
            Evoluir
          </button>}
          </div>
          <div className="flex">
            <button
              type="button"
              onClick={handleClickPrintAnamnese}
              className="flex items-center justify-center w-48 bg-indigo-600 hover:bg-indigo-700 text-white ml-2 px-6 py-2 rounded-md focus:outline-none focus-visible:ring focus-visible:ring-indigo-500 focus-visible:ring-opacity-50"
            >
              <PrinterIcon className="w-8 h-8 text-white-600 exclude-from-print" />
              <p className="text-white-600 px-2">Anamnese</p>
            </button>
            <button
              type="button"
              onClick={handleClickPrintEvolucao}
              className="flex items-center justify-center w-48 bg-indigo-600 hover:bg-indigo-700 text-white ml-2 px-6 py-2 rounded-md focus:outline-none focus-visible:ring focus-visible:ring-indigo-500 focus-visible:ring-opacity-50"
            >
              <PrinterIcon className="w-8 h-8 text-white-600 exclude-from-print" />
              <p className="text-white-600 px-2">Evolução</p>
            </button>
          </div>
        </div>
          <div className="border border-gray-300 p-8 rounded-lg">
            <div className="flex items-center justify-between mb-8">
              <div className="flex items-center">
                {logo && (
                  <img src={logo} alt="Logo" className="h-10 w-auto mr-4" />
                )}
              </div>
              <div>
                <p className="text-sm text-gray-600">
                  Data e Hora: {getCurrentDateTime()}
                </p>
                <p className="text-sm text-gray-600">
                  Usuário: {nome? nome : ''} {ocupacao ? ':' : ''} {ocupacao ? ocupacao : ''}
                </p>
              </div>
            </div>
            {pac.length > 0 && (
              <div className="space-y-4">
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <p className="text-lg font-semibold">Nome do Paciente:</p>
                    <p>{pac[0].nome}</p>
                  </div>
                  <div>
                    <p className="text-lg font-semibold">Idade:</p>
                    <p>
                      {`${calculateAge(pac[0].dt_nasc).years} anos, ${calculateAge(
                        pac[0].dt_nasc
                      ).months} meses, ${calculateAge(pac[0].dt_nasc).days} dias`}
                    </p>
                  </div>
                  <div>
                    <p className="text-lg font-semibold">Endereço:</p>
                    <p>{`${pac[0].logradouro} ${pac[0].logradouro? ',' : ''} ${pac[0].numeral} ${pac[0].numeral? ',' : ''} 
                    ${pac[0].bairro} ${pac[0].bairro? ',' : ''} ${pac[0].cidade} ${pac[0].cidade? ',' : ''} ${pac[0].estado} ${pac[0].estado? ',' : ''} 
                     ${pac[0].cep? 'CEP:' : ''} ${pac[0].cep}
                    `}</p>
                  </div>
                  <div>
                    <p className="text-lg font-semibold">Nome/Filiação/Contato:</p>
                    <p>{pac[0].responsaveis && pac[0].responsaveis.length > 0 ? pac[0].responsaveis.map((resp, index) => (
                    <span key={index}>
                      {`${resp.nome} (${resp.filiacao} / ${resp.telefone})`}
                      <br />
                    </span>
                  )) : ''}</p>
                  </div>
                  <div>
                    <p className="text-lg font-semibold">
                      Convenio de Saúde:
                    </p>
                    <p>
                      {pac[0].convenio}
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal
      isOpen={modalHistoricoIsOpen}
      onRequestClose={() => setHistoricoModalIsOpen(false)}
      style={{
        content: {
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: '#fff',
          border: '1px solid #ccc',
          borderRadius: '8px',
          padding: '20px',
          maxWidth: '90%',
          height: '75%',
          maxHeight: '90%',
          overflow: 'auto',
        },
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 1000,
        },
      }}
    >
      <div className="bg-white p-8 rounded-lg">
        {showMessage && (
          <MessageBox
            message={message}
            onClose={() => {
              handleCloseMessage();
            }}
            icon={
              showIcon ? (
                <ExclamationCircleIcon className="w-10 h-10 mx-auto text-red-500" />
              ) : null
            }
          />
        )}
        <h2 className="text-lg font-semibold mb-4">Condição Clínica</h2>
        <Tabs>
          <TabList className="flex mb-4 border-b border-gray-300">
            <Tab className="py-2 px-4 text-sm font-semibold cursor-pointer border-b-2 border-transparent hover:border-blue-500 focus:outline-none">
            Histórico
            </Tab>
            <Tab className="py-2 px-4 text-sm font-semibold cursor-pointer border-b-2 border-transparent hover:border-blue-500 focus:outline-none">
            Diagnósticos
            </Tab>
          </TabList>
          
          <TabPanel>
              <div className="mb-4">
                <label className="block text-sm font-semibold mb-2" htmlFor="historicoMedico">
                  Histórico Médico:
                </label>
                <textarea
                  id="historicoMedico"
                  className="w-full p-2 border border-gray-300 rounded-md"
                  value={historicoMedico}
                  onChange={(e) => setHistoricoMedico(e.target.value)}
                />
              </div>

              <div className="mb-4">
                <label className="block text-sm font-semibold mb-2" htmlFor="alergias">
                  Alergias:
                </label>
                <textarea
                  id="alergias"
                  className="w-full p-2 border border-gray-300 rounded-md"
                  value={alergias}
                  onChange={(e) => setAlergias(e.target.value)}
                />
              </div>

              <div className="mb-4">
                <label className="block text-sm font-semibold mb-2" htmlFor="medicamentosEmUso">
                  Medicamentos em Uso:
                </label>
                <textarea
                  id="medicamentosEmUso"
                  className="w-full p-2 border border-gray-300 rounded-md"
                  value={medicamentosEmUso}
                  onChange={(e) => setMedicamentosEmUso(e.target.value)}
                />
              </div>

              <div className="mb-4">
                <label className="block text-sm font-semibold mb-2" htmlFor="motivo">
                  Motivo:
                </label>
                <textarea
                  id="motivo"
                  className="w-full p-2 border border-gray-300 rounded-md"
                  value={motivo}
                  onChange={(e) => setMotivo(e.target.value)}
                />
              </div>
          </TabPanel>

          <TabPanel>
          <div className="relative mb-4">
                <label className="block text-sm font-semibold mb-2" htmlFor="diagnosticoInput">
                  CID Geral:
                </label>
                <input
                  id="diagnosticoInput"
                  type="text"
                  className="w-full p-2 border border-gray-300 rounded-md"
                  value={diagnosticoInput}
                  onChange={handleDiagnosticoChange}
                  onKeyDown={handleKeyDown}
                  readOnly={diagnosticoSelecionado !== ""}
                />
                {diagnosticoInput && (
                  <div
                    className="absolute z-10 mt-1 w-full bg-white border border-gray-300 rounded-md shadow-md max-h-40 overflow-y-auto"
                    style={{ display: showOptions ? 'block' : 'none' }}
                    aria-expanded={showOptions}
                    aria-controls="diagnosticoOptions"
                  >
                    {cidsFiltrados.slice(0, 10).map((cid, index) => (
                      <div
                        key={index}
                        className="p-2 cursor-pointer hover:bg-gray-200"
                        onClick={() => handleCidSelect(cid.cid, cid.diagnostico)}
                      >
                        {cid.cid} - {cid.diagnostico}
                      </div>
                    ))}
                  </div>
                )}
              </div>

              <div className="relative mb-4">
                <label className="block text-sm font-semibold mb-2" htmlFor="diagnosticoFisioInput">
                  CID Fisio:
                </label>
                <input
                  id="diagnosticoFisioInput"
                  type="text"
                  className="w-full p-2 border border-gray-300 rounded-md"
                  value={diagnosticoFisioInput}
                  onChange={handleDiagnosticoFisioChange}
                  onKeyDown={handleKeyDownFisio}
                  readOnly={diagnosticoFisioSelecionado !== ""}
                />
                {diagnosticoFisioInput && (
                  <div
                    className="absolute z-10 mt-1 w-full bg-white border border-gray-300 rounded-md shadow-md max-h-40 overflow-y-auto"
                    style={{ display: showOptionsFisio ? 'block' : 'none' }}
                    aria-expanded={showOptionsFisio}
                    aria-controls="diagnosticoFisioOptions"
                  >
                    {cidsFisioFiltrados.slice(0, 10).map((cid, index) => (
                      <div
                        key={index}
                        className="p-2 cursor-pointer hover:bg-gray-200"
                        onClick={() => handleCidFisioSelect(cid.cid, cid.diagnostico)}
                      >
                        {cid.cid} - {cid.diagnostico}
                      </div>
                    ))}
                  </div>
                )}
              </div>

              <div className="relative mb-4">
                <label className="block text-sm font-semibold mb-2" htmlFor="diagnosticoFonoInput">
                  CID Fono:
                </label>
                <input
                  id="diagnosticoFonoInput"
                  type="text"
                  className="w-full p-2 border border-gray-300 rounded-md"
                  value={diagnosticoFonoInput}
                  onChange={handleDiagnosticoFonoChange}
                  onKeyDown={handleKeyDownFono}
                  readOnly={diagnosticoFonoSelecionado !== ""}
                />
                {diagnosticoFonoInput && (
                  <div
                    className="absolute z-10 mt-1 w-full bg-white border border-gray-300 rounded-md shadow-md max-h-40 overflow-y-auto"
                    style={{ display: showOptionsFono ? 'block' : 'none' }}
                    aria-expanded={showOptionsFono}
                    aria-controls="diagnosticoFonoOptions"
                  >
                    {cidsFonoFiltrados.slice(0, 10).map((cid, index) => (
                      <div
                        key={index}
                        className="p-2 cursor-pointer hover:bg-gray-200"
                        onClick={() => handleCidFonoSelect(cid.cid, cid.diagnostico)}
                      >
                        {cid.cid} - {cid.diagnostico}
                      </div>
                    ))}
                  </div>
                )}
              </div>

              <div className="relative mb-4">
                <label className="block text-sm font-semibold mb-2" htmlFor="diagnosticoPsicoInput">
                  CID Psico:
                </label>
                <input
                  id="diagnosticoPsicoInput"
                  type="text"
                  className="w-full p-2 border border-gray-300 rounded-md"
                  value={diagnosticoPsicoInput}
                  onChange={handleDiagnosticoPsicoChange}
                  onKeyDown={handleKeyDownPsico}
                  readOnly={diagnosticoPsicoSelecionado !== ""}
                />
                {diagnosticoPsicoInput && (
                  <div
                    className="absolute z-10 mt-1 w-full bg-white border border-gray-300 rounded-md shadow-md max-h-40 overflow-y-auto"
                    style={{ display: showOptionsPsico ? 'block' : 'none' }}
                    aria-expanded={showOptionsPsico}
                    aria-controls="diagnosticoPsicoOptions"
                  >
                    {cidsPsicoFiltrados.slice(0, 10).map((cid, index) => (
                      <div
                        key={index}
                        className="p-2 cursor-pointer hover:bg-gray-200"
                        onClick={() => handleCidPsicoSelect(cid.cid, cid.diagnostico)}
                      >
                        {cid.cid} - {cid.diagnostico}
                      </div>
                    ))}
                  </div>
                )}
            </div>
          </TabPanel>
        </Tabs>
        <div className="mt-6 flex justify-end">
          <button
            type="button"
            onClick={() => setHistoricoModalIsOpen(false)}
            className="bg-gray-300 hover:bg-gray-400 text-white px-4 py-2 rounded-md"
          >
            Fechar
          </button>
          <button
            type="submit"
            onClick={handleSubmit}
            className="ml-2 bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-md"
          >
            Salvar
          </button>
        </div>
      </div>
    </Modal>
    <Modal
      isOpen={modalEvolucaoIsOpen}
      onRequestClose={() => setEvolucaoModalIsOpen(false)}
      style={{
        content: {
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: '#fff',
          border: '1px solid #ccc',
          borderRadius: '8px',
          padding: '20px',
          maxWidth: '90%',
          height: '75%',
          maxHeight: '90%',
          overflow: 'auto',
        },
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 1000,
        },
      }}
    >
      <div className="bg-white p-8 rounded-lg">
        {showMessage && (
          <MessageBox
            message={message}
            onClose={() => {
              handleCloseMessage();
            }}
            icon={
              showIcon ? (
                <ExclamationCircleIcon className="w-10 h-10 mx-auto text-red-500" />
              ) : null
            }
          />
        )}
        <h2 className="text-lg font-semibold mb-4"> Evoluir Paciente </h2>
            <div className="mb-4">
              <label className="block text-sm font-semibold mb-2" htmlFor="historicoProcedimentos">
                Evolução / Procedimentos:
              </label>
              <textarea
                id="historicoProcedimentos"
                className="w-full p-2 border border-gray-300 rounded-md"
                value={historicoProcedimentos}
                onChange={(e) => setHistoricoProcedimentos(e.target.value)}
              />
            </div>
        <div className="mt-6 flex justify-end">
          <button
            type="button"
            onClick={() => setEvolucaoModalIsOpen(false)}
            className="bg-gray-300 hover:bg-gray-400 text-white px-4 py-2 rounded-md"
          >
            Fechar
          </button>
          <button
            type="submit"
            onClick={handleSubmit}
            className="ml-2 bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-md"
          >
            Salvar
          </button>
        </div>
      </div>
    </Modal>
    </div>
  );
};
