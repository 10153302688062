import { useEffect, useState } from "react";
import { useApi } from "../../hooks/useApi";
import { User } from "../../types/User";
import { AuthContext } from "./AuthContext";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const AuthProvider = ({ children }: { children: JSX.Element }) => {
  const [user, setUser] = useState<User | null>(null);
  const api = useApi();

  useEffect(() => {
    const validateToken = async () => {
      try {
        const storageData = localStorage.getItem('authToken');
        if (storageData) {
          const data = await api.validateToken(storageData);
          if (data.user) {
            setUser(data.user);
          }
        }
      } catch (error) {
        if (axios.isAxiosError(error) && error.response?.status === 401) {
          localStorage.removeItem('authToken');
          toast.warning('Sessão expirada. Faça login novamente.');
          setTimeout(() => {
            window.location.href = '/login'; // Redireciona após 10 segundos
          }, 5000);
        } else {
          console.error('Erro ao validar token:', error);
          toast.error('Erro ao validar token.');
        }
      }
    };

    validateToken();
  }, [api]);

  const signin = async (email: string, password: string) => {
    setToken('');
    const data = await api.signin(email, password);
    if (data.user && data.token) {
      setUser(data.user);
      setToken(data.token);
      return true;
    }
    return false;
  };

  const updateuser = async (id_user: string, email: string, password: string, newpassword: string) => {
    const data = await api.updateuser(id_user, email, password, newpassword);
    if (data) {
      return true;
    }
    return false;
  };

  const signout = async () => {
    setUser(null);
    setToken('');
    await api.logout();
  };

  const setToken = (token: string) => {
    if (token !== '') {
      localStorage.setItem('authToken', token);
    } else {
      localStorage.removeItem('authToken');
    }
  };

  return (
    <AuthContext.Provider value={{ user, signin, updateuser, signout }}>
      {children}
      <ToastContainer />
    </AuthContext.Provider>
  );
};
