// src/App.tsx
import React, { useState } from 'react';
import { AgendaManha } from './Manha';
import { AgendaTarde } from './Tarde';

const Agenda: React.FC = () => {
  const [selectedAgenda, setSelectedAgenda] = useState<'manha' | 'tarde'>('manha');

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      {/* Área de seleção Manhã/Tarde */}
      <nav className="flex justify-center space-x-2 p-3 bg-gray-200">
        <button
          onClick={() => setSelectedAgenda('manha')}
          className={`px-4 py-2 rounded ${selectedAgenda === 'manha' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'}`}
        >
          Manhã
        </button>
        <button
          onClick={() => setSelectedAgenda('tarde')}
          className={`px-4 py-2 rounded ${selectedAgenda === 'tarde' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'}`}
        >
          Tarde
        </button>
      </nav>

      {/* Área da agenda */}
      <div className="flex-1 bg-white p-3 rounded shadow-md">
        {selectedAgenda === 'manha' && <AgendaManha />}
        {selectedAgenda === 'tarde' && <AgendaTarde />}
      </div>
    </div>
  );
};

export default Agenda;
