import { ChangeEvent, useContext, useState } from "react";
import { AuthContext } from "../../contexts/Auth/AuthContext";
import MessageBox from "../../components/Utils/MessageBox";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline"

export const Perfil = () => {
    const auth = useContext(AuthContext);
    const [email, setEmail] = useState<string | undefined>(auth.user?.email);
    const [password, setPassword] = useState<string>('');
    const [newPassword, setNewPassword] = useState<string>('');
    const [confirmNewpassword, setConfirmNewPassword] = useState('');
    const [message, setMessage] = useState<string>('');
    const [showMessage, setShowMessage] = useState<boolean>(false);
    const [showIcon, setShowIcon] = useState<boolean>(false);
    const idUser = auth.user?.id_user;

    const handleSubmit = async () => {
      if (idUser && email && password && newPassword && newPassword === confirmNewpassword) {
          const idUserAltered = await auth.updateuser(idUser, email, password, newPassword);
          if (idUserAltered) {
              setMessage('Dados inseridos com sucesso');
          } else {
            setMessage("Não foi possível atualizar os dados.");
            setShowMessage(true);
          }
      } else {
        setMessage("Por favor, preencha todos os campos.");
        setShowMessage(true);
      }
      setShowMessage(true);
  }

    const handleEmailInput = (event: ChangeEvent<HTMLInputElement>) => {
      setEmail(event.target.value);
    }

    const handlePasswordInput = (event: ChangeEvent<HTMLInputElement>) => {
      setPassword(event.target.value);
    }

    const handleNewPasswordInput = (event: ChangeEvent<HTMLInputElement>) => {
      setNewPassword(event.target.value);
    }

    const handleConfirmNewPasswordInput = (event: ChangeEvent<HTMLInputElement>) => {
      setConfirmNewPassword(event.target.value);
    }

    const handleCloseMessage = () => {
      setShowMessage(false);
    };

  return (
    <div className="min-h-screen flex items-center justify-center pt-12">
      {showMessage && <MessageBox message={message}
      onClose={() => {
      setShowIcon(false);
      handleCloseMessage();
      }}  
      icon={showIcon ? 
      <ExclamationCircleIcon className="w-10 h-10 mx-auto text-red-500"/> : null}
      />}
      <form className="w-full max-w-md">
      <div className="space-y-12">
        <div className="border-b border-gray-900/10 pb-12">
          <h2 className="text-base font-semibold leading-7 text-gray-900">Informações pessoais</h2>
          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="col-span-full">
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                Endereço de Email
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  value={email}
                  className="text-center block w-full rounded-md border-0 py-1.5 text-grays-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  onChange={handleEmailInput}
                  required
                />
              </div>
            </div>
            <div className="col-span-full">
              <label htmlFor="senha" className="block text-sm font-medium leading-6 text-gray-900">
                Senha Atual
              </label>
              <div className="mt-2">
                <input
                  id="senha"
                  name="senha"
                  type="password"
                  autoComplete="senha"
                  className="text-center block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  onChange={handlePasswordInput}
                  required
                />
              </div>
            </div>
            <div className="col-span-full">
              <label htmlFor="novasenha" className="block text-sm font-medium leading-6 text-gray-900">
                Nova Senha
              </label>
              <div className="mt-2">
                <input
                  id="novasenha"
                  name="novasenha"
                  type="password"
                  autoComplete="senha"
                  className="text-center block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  onChange={handleNewPasswordInput}
                  required
                />
              </div>
            </div>
            <div className="col-span-full">
              <label htmlFor="novasenha-confirma" className="block text-sm font-medium leading-6 text-gray-900">
                Confirmar Nova Senha
              </label>
              <div className="mt-2">
                <input
                  id="novasenha-confirma"
                  name="novasenha-confirma"
                  type="password"
                  autoComplete="novasenha-confirma"
                  className="text-center block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  onChange={handleConfirmNewPasswordInput}
                  required
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-6 flex items-center justify-end gap-x-6 pb-12">
        <button
          type="button"
          className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={handleSubmit}
        >
          Salvar
        </button>
      </div>
    </form>

</div>

    );
}