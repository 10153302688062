import React from "react";
import { Button, Card, CardFooter, Typography } from "@material-tailwind/react";
import * as pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import logo from "../../images/logo/logo.png";
import { TDocumentDefinitions } from "pdfmake/interfaces";

// Defina a variável newVfs com o sistema de fontes do pdfmake
const newVfs = pdfFonts.pdfMake.vfs;

// Configure o pdfmake para usar newVfs
(pdfMake as any).vfs = newVfs;

interface DetailModalProps {
  isOpen: boolean;
  onClose: () => void;
  data: { [key: string]: any };
}

const DetailModal: React.FC<DetailModalProps> = ({ isOpen, onClose, data }) => {
  if (!isOpen) return null;

  const generatePDF = () => {
    const keys = Object.keys(data);
    const firstKey = keys.length > 0 ? keys[0] : "Detalhes";
    const firstValue = keys.length > 0 ? data[firstKey] : "";

    const documentDefinition: TDocumentDefinitions = {
      content: [
        {
          image: logo,
          width: 150,
          alignment: 'center',
          margin: [0, 0, 0, 20]
        },
        {
          text: `${firstValue}`,
          style: 'header',
        },
        ...keys.slice(1).map(key => ({
          // Usando um array de textos para formatar a chave em negrito e o valor normal
          text: [
            { text: `${key}:`, style: 'key' },
            { text: ` ${data[key]}`, style: 'value' }
          ],
          margin: [0, 0, 0, 10] as [number, number, number, number] // Adiciona espaço abaixo de cada linha
        }))
      ],
      styles: {
        header: {
          fontSize: 22,
          bold: true,
          margin: [0, 0, 0, 20],
          alignment: 'center',
        },
        key: {
          fontSize: 12,
          bold: true,
        },
        value: {
          fontSize: 12,
        }
      }
    };

    pdfMake.createPdf(documentDefinition).getBlob((blob) => {
      const url = URL.createObjectURL(blob);
      window.open(url, '_blank');
    });
  };

  return (
    <div className="fixed inset-0 z-10 flex items-start justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none bg-black bg-opacity-50">
      <div className="relative w-auto w-[50vw] max-w-[600px]" style={{ marginTop: '10vh', marginBottom: '10vh' }}>
        <Card placeholder='' className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
          <div className="flex items-start justify-between p-5 border-b border-solid rounded-t border-blue-gray-200">
            <h3 className="text-3xl font-semibold">Detalhes</h3>
            <button
              className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
              onClick={onClose}
            >
              <span className="text-black h-6 w-6 text-2xl block outline-none focus:outline-none">×</span>
            </button>
          </div>
          <div className="relative p-6 flex-auto">
            {Object.keys(data).map((key, index) => (
              <div key={index} className="mb-4">
                <Typography placeholder='' variant="small" color="blue-gray" className="font-normal">
                  <strong>{key}:</strong> {data[key]}
                </Typography>
              </div>
            ))}
          </div>
          <CardFooter placeholder='' className="flex items-center justify-end p-6 border-t border-solid rounded-b border-blue-gray-200">
            <Button
              placeholder=''
              color="blue-gray"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              onClick={onClose}
            >
              Fechar
            </Button>
            <Button
              placeholder=''
              color="blue-gray"
              className="bg-green-500 hover:bg-green-700 text-white font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              onClick={generatePDF}
            >
              Imprimir
            </Button>
          </CardFooter>
        </Card>
      </div>
    </div>
  );
};

export default DetailModal;
