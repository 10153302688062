import { useApi } from "../../hooks/useApi";
import { GeralContext } from "./GeralContext";
import { SalaParams } from "../../types/Sala";
import { ProcedimentoParams } from "../../types/Procedimento";
import { EspecParams } from "../../types/Espec";
import { ConvenioParams } from "../../types/Convenio";
import { useContext } from 'react';
import { AgendaParams } from "../../types/Agenda";
import { CidParams } from "../../types/Cid";
import { AtividadeParams } from "../../types/Atividade";

// Define tipos específicos de funções para cada tipo de ação
interface ActionFunctions {
    add: (params: GenericParams) => Promise<boolean>;
    edit: (params: GenericParams) => Promise<boolean>;
    delete: (id: string, id_user: string) => Promise<boolean>;
    replicar:(dataAtual: string, nextWeek: string, id_user: string) => Promise<boolean>;
    fill: () => Promise<any>;
}

// Define o tipo genérico de parâmetro que pode ser passado para as funções de ação
type GenericParams = SalaParams | EspecParams | ConvenioParams | ProcedimentoParams | AgendaParams | CidParams | AtividadeParams;

// Define o tipo para as ações, que contém todas as funções de ação
type Actions = {
    [key in keyof ActionFunctions]: ActionFunctions[key];
};

// Função para criar as ações
const createActions = (api: any, actionPrefix: string): Actions => ({
    add: async (params: GenericParams) => {
            const data = await api[actionPrefix + 'add'](params);
            return data;
    },
    edit: async (params: GenericParams) => {
            const data = await api[actionPrefix + 'edit'](params);
            return data ;
    },
    delete: async (id: string, id_user: string) => {
            const data = await api[actionPrefix + 'delete'](id, id_user);
            return data ;
    },
    replicar: async (dataAtual: string, nextWeek: string, id_user: string) => {
            const data = await api[actionPrefix + 'replicar'](dataAtual,nextWeek, id_user);
            return data;
    },
    fill: async (period?: string, dayOfWeek?: string) => {
        try {
            return await api[actionPrefix + 'fill'](period, dayOfWeek);
        } catch (error) {
            console.error('Erro na chamada da API:', error);
            return null;
        }
    }
});

export const GeralProvider = ({ children }: { children: JSX.Element }) => {
    const api = useApi();

    // Cria as ações para cada tipo
    const salaActions = createActions(api, 'sala');
    const procedimentoActions = createActions(api, 'proc');
    const especActions = createActions(api, 'espec');
    const atividadeActions = createActions(api, 'ativ');
    const convenioActions = createActions(api, 'convenio');
    const agendaActions = createActions(api, 'agenda');
    const cidActions = createActions(api, 'cid');


    return (
        <GeralContext.Provider value={{
            salaActions,
            procedimentoActions,
            especActions,
            atividadeActions,
            convenioActions,
            agendaActions,
            cidActions
        }}>
            {children}
        </GeralContext.Provider>
    );
}

export const useGeralContext = () => useContext(GeralContext);
