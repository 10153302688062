import { useContext, useEffect, useState } from "react";
import { GeralContext } from "../../../../contexts/Geral/GeralContext";
import { Link, useLocation } from "react-router-dom";
import MessageBox from "../../../../components/Utils/MessageBox";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { AuthContext } from "../../../../contexts/Auth/AuthContext";

interface AtividadeItem {
  id_ativ: string;
  atividade: string;
}

export const EditaProcedimento = () => {
  const { procedimentoActions, atividadeActions } = useContext(GeralContext);
  const [codProc, setCodProc] = useState<string>('');
  const [procedimento, setProcedimento] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [showIcon, setShowIcon] = useState<boolean>(false);
  const [atividades, setAtividades] = useState<AtividadeItem[]>([]);
  const [selectedAtiv, setSelectedAtiv] = useState<{ id_ativ: string }[]>([]);
  const location = useLocation();
  const selectedItem = location.state?.selectedItem;
  const auth = useContext(AuthContext);
  const idUser = auth.user?.id_user;

  useEffect(() => {
    if (selectedItem.codproc !== undefined) {
      setCodProc(selectedItem.codproc);
      setProcedimento(selectedItem.procedimento);
      setSelectedAtiv(selectedItem.atividades_selecionadas);
      // Atualizar outras propriedades conforme necessário
    }
  }, [selectedItem]);

  useEffect(() => {
    const fetchAtividades = async () => {
      try {
        const atividadesData = await atividadeActions.fill();
        setAtividades(atividadesData);
        setSelectedAtiv(selectedItem.atividades.map((atividade: any) => ({ id_ativ: atividade.id_ativ })));
      } catch (error) {
        console.error("Erro ao buscar atividades:", error);
      }
    };
    fetchAtividades();
  }, [atividadeActions, selectedItem]);

  const handleCheckboxChange = (id_ativ: string) => {
    setSelectedAtiv(prevSelected => {
      if (prevSelected.some(item => item.id_ativ === id_ativ)) {
        // Remove o item da seleção se já estiver selecionado
        return prevSelected.filter(item => item.id_ativ !== id_ativ);
      } else {
        // Adiciona o item à seleção se não estiver selecionado
        return [...prevSelected, { id_ativ }];
      }
    });
  };

  const handleSubmit = async () => {
    if (procedimento && idUser) {
      const ItemUpdated = await procedimentoActions.edit({
        codproc: codProc,
        procedimento: procedimento,
        id_user: idUser,
        atividades_selecionadas: selectedAtiv // Adiciona atividades selecionadas ao objeto de atualização
      });
      if (ItemUpdated) {
        setMessage('Dados atualizados com sucesso');
      } else {
        setMessage("Não foi possível atualizar os dados.");
        setShowIcon(true);
      }
    } else {
      setMessage("Por favor, preencha todos os campos.");
      setShowIcon(true);
    }
    setShowMessage(true);
  };

  const handleCloseMessage = () => {
    setShowMessage(false);
    if (!showIcon) {
      window.location.href = '/procedimento'; // Redireciona apenas se não houver ícone de erro
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center pt-12">
      {showMessage && (
        <MessageBox
          message={message}
          onClose={() => {
            setShowIcon(false);
            handleCloseMessage();
          }}
          icon={showIcon ? <ExclamationCircleIcon className="w-10 h-10 mx-auto text-red-500" /> : null}
        />
      )}
      <form className="w-full max-w-md">
        <div className="space-y-12">
          <div className="border-b border-gray-900/10 pb-12">
            <h2 className="text-3xl font-bold leading-8 text-gray-900 border-b-4 border-indigo-500 pb-2">Editar Procedimento</h2>
            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="col-span-full">
                <label htmlFor="codproc" className="block text-sm font-medium leading-6 text-gray-900">
                  Código do Procedimento
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="codproc"
                    id="codproc"
                    autoComplete="given-name"
                    value={codProc}
                    className="block w-full rounded-md border-0 py-1.5 text-center text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={(e) => setCodProc(e.target.value)}
                    required
                    disabled
                  />
                </div>
              </div>

              <div className="col-span-full">
                <label htmlFor="procedimento" className="block text-sm font-medium leading-6 text-gray-900">
                  Procedimento
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="procedimento"
                    id="procedimento"
                    autoComplete="given-name"
                    value={procedimento}
                    className="block w-full rounded-md border-0 py-1.5 text-center text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={(e) => setProcedimento(e.target.value)}
                    required
                  />
                </div>
              </div>

              <div className="col-span-full">
                <label htmlFor="atividades" className="block text-sm font-medium leading-6 text-gray-900">
                  Atividades
                </label>
                <div className="mt-2 space-y-2">
                  {atividades.map(atividade => (
                    <div key={atividade.id_ativ} className="flex items-center">
                      <input
                        type="checkbox"
                        id={`atividade-${atividade.id_ativ}`}
                        checked={selectedAtiv.some(item => item.id_ativ === atividade.id_ativ)}
                        onChange={() => handleCheckboxChange(atividade.id_ativ)}
                        className="h-4 w-4 text-indigo-600 focus:ring-indigo-600 border-gray-300 rounded"
                      />
                      <label htmlFor={`atividade-${atividade.id_ativ}`} className="ml-2 text-sm text-gray-900">
                        {atividade.atividade}
                      </label>
                    </div>
                  ))}
                </div>
              </div>

            </div>
          </div>
        </div>

        <div className="mt-6 flex items-center justify-end gap-x-6 pb-12">
          <Link to="/procedimento" className="text-indigo-600 hover:text-indigo-900">
            Voltar
          </Link>
          <button
            type="button"
            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={handleSubmit}
          >
            Salvar
          </button>
        </div>
      </form>
    </div>
  );
};
