import './App.css';
import { Route, Routes, Navigate } from 'react-router-dom';
import { Footer } from './components/Footer';
import { Header } from './components/Header';
import { RequireAuth } from './contexts/Auth/RequireAuth';
import { Perfil } from './pages/Perfil';
import { Login } from './pages/Login';
import { GerenciaPaciente } from './pages/Pessoa/Paciente/Gerencia';
import { AdicionaPaciente } from './pages/Pessoa/Paciente/Add';
import { EditaPaciente } from './pages/Pessoa/Paciente/Edit';
import { GerenciaSala } from './pages/Geral/Sala/Gerencia';
import { AdicionaSala } from './pages/Geral/Sala/Add';
import { EditaSala } from './pages/Geral/Sala/Edit';
import { GerenciaEspecialidade } from './pages/Geral/Especialidade/Gerencia';
import { AdicionaEspecialidade } from './pages/Geral/Especialidade/Add';
import { EditaEspecialidade } from './pages/Geral/Especialidade/Edit';
import { GerenciaConvenio } from './pages/Geral/Convenio/Gerencia';
import { AdicionaConvenio } from './pages/Geral/Convenio/Add';
import { EditaConvenio } from './pages/Geral/Convenio/Edit';
import { GerenciaProcedimento } from './pages/Geral/Procedimento/Gerencia';
import { AdicionaProcedimento } from './pages/Geral/Procedimento/Add';
import { EditaProcedimento } from './pages/Geral/Procedimento/Edit';
import { GerenciaTerapeuta } from './pages/Pessoa/Terapeuta/Gerencia';
import { AdicionaTerapeuta } from './pages/Pessoa/Terapeuta/Add';
import { EditaTerapeuta } from './pages/Pessoa/Terapeuta/Edit';
import { GerenciaMonitor } from './pages/Pessoa/Monitor/Gerencia';
import { AdicionaMonitor } from './pages/Pessoa/Monitor/Add';
import { EditaMonitor } from './pages/Pessoa/Monitor/Edit';
import { AgendaManha } from './pages/Agendamento/Manha';
import { Prontuario } from './pages/Pessoa/Paciente/Prontuario';
import { AgendaTarde } from './pages/Agendamento/Tarde';
import Agenda from './pages/Agendamento/Agenda';
import { GerenciaAtividade } from './pages/Geral/Atividade/Gerencia';
import { AdicionaAtividade } from './pages/Geral/Atividade/Add';
import { EditaAtividade } from './pages/Geral/Atividade/Edit';

function App() {
  return (
    <div className="App min-h-screen flex flex-col">
      <header className="bg-white">
        <Header/>
      </header>
      <div className="flex-grow">
        <hr />
        <Routes>
          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="/login" element={<Login />} />
          <Route path="/perfil" element={<RequireAuth><Perfil /></RequireAuth>} />
          <Route path="/paciente/*" element={<RequireAuth><PacienteRoutes/></RequireAuth>} />
          <Route path="/sala/*" element={<RequireAuth><SalaRoutes /></RequireAuth>} />
          <Route path="/especialidade/*" element={<RequireAuth><EspecialidadeRoutes /></RequireAuth>} />
          <Route path="/convenio/*" element={<RequireAuth><ConvenioRoutes /></RequireAuth>} />
          <Route path="/procedimento/*" element={<RequireAuth><ProcedimentoRoutes /></RequireAuth>} />
          <Route path="/atividade/*" element={<RequireAuth><AtividadeRoutes /></RequireAuth>} />
          <Route path="/terapeuta/*" element={<RequireAuth><TerapeutaRoutes /></RequireAuth>} />
          <Route path="/auxiliar/*" element={<RequireAuth><MonitorRoutes /></RequireAuth>} />
          <Route path="/agenda" element={<RequireAuth><Agenda /></RequireAuth>} />
          <Route path="/*" element={<RequireAuth><Navigate to="/*" /></RequireAuth>} />
        </Routes>
      </div>
      <Footer/>
    </div>
  );
}

function PacienteRoutes() {
  return (
    <Routes>
      <Route path="/" element={<RequireAuth terapeuta={1} monitor={1} ><GerenciaPaciente /></RequireAuth>} />
      <Route path="/add" element={<RequireAuth terapeuta={0} monitor={0} ><AdicionaPaciente /></RequireAuth>} />
      <Route path="/edit" element={<RequireAuth terapeuta={0} monitor={0} ><EditaPaciente /></RequireAuth>} />
      <Route path="/prontuario" element={<RequireAuth terapeuta={1} monitor={0} ><Prontuario /></RequireAuth>} />
    </Routes>
  );
}

function SalaRoutes() {
  return (
    <Routes>
      <Route path="/" element={<RequireAuth terapeuta={1} monitor={1} ><GerenciaSala /></RequireAuth>} />
      <Route path="/add" element={<RequireAuth terapeuta={0} monitor={0} ><AdicionaSala /></RequireAuth>} />
      <Route path="/edit" element={<RequireAuth terapeuta={0} monitor={0} ><EditaSala /></RequireAuth>} />
    </Routes>
  );
}

function EspecialidadeRoutes() {
  return (
    <Routes>
      <Route path="/" element={<RequireAuth terapeuta={1} monitor={1} ><GerenciaEspecialidade /></RequireAuth>} />
      <Route path="/add" element={<RequireAuth terapeuta={0} monitor={0} ><AdicionaEspecialidade /></RequireAuth>} />
      <Route path="/edit" element={<RequireAuth terapeuta={0} monitor={0} ><EditaEspecialidade /></RequireAuth>} />
    </Routes>
  );
}

function ConvenioRoutes() {
  return (
    <Routes>
      <Route path="/" element={<RequireAuth terapeuta={1} monitor={1} ><GerenciaConvenio /></RequireAuth>} />
      <Route path="/add" element={<RequireAuth terapeuta={0} monitor={0} ><AdicionaConvenio /></RequireAuth>} />
      <Route path="/edit" element={<RequireAuth terapeuta={0} monitor={0} ><EditaConvenio /></RequireAuth>} />
    </Routes>
  );
}

function ProcedimentoRoutes() {
  return (
    <Routes>
      <Route path="/" element={<RequireAuth terapeuta={1} monitor={1} ><GerenciaProcedimento /></RequireAuth>} />
      <Route path="/add" element={<RequireAuth terapeuta={0} monitor={0} ><AdicionaProcedimento /></RequireAuth>} />
      <Route path="/edit" element={<RequireAuth terapeuta={0} monitor={0} ><EditaProcedimento /></RequireAuth>} />
    </Routes>
  );
}

function AtividadeRoutes() {
  return (
    <Routes>
      <Route path="/" element={<RequireAuth terapeuta={1} monitor={1} ><GerenciaAtividade /></RequireAuth>} />
      <Route path="/add" element={<RequireAuth terapeuta={0} monitor={0} ><AdicionaAtividade /></RequireAuth>} />
      <Route path="/edit" element={<RequireAuth terapeuta={0} monitor={0} ><EditaAtividade /></RequireAuth>} />
    </Routes>
  );
}

function TerapeutaRoutes() {
  return (
    <Routes>
      <Route path="/" element={<RequireAuth terapeuta={1} monitor={1} ><GerenciaTerapeuta /></RequireAuth>} />
      <Route path="/add" element={<RequireAuth terapeuta={0} monitor={0} ><AdicionaTerapeuta /></RequireAuth>} />
      <Route path="/edit" element={<RequireAuth terapeuta={0} monitor={0} ><EditaTerapeuta /></RequireAuth>} />
    </Routes>
  );
}

function MonitorRoutes() {
  return (
    <Routes>
      <Route path="/" element={<RequireAuth terapeuta={1} monitor={1} ><GerenciaMonitor /></RequireAuth>} />
      <Route path="/add" element={<RequireAuth terapeuta={0} monitor={0} ><AdicionaMonitor /></RequireAuth>} />
      <Route path="/edit" element={<RequireAuth terapeuta={0} monitor={0} ><EditaMonitor /></RequireAuth>} />
    </Routes>
  );
}

export default App;
