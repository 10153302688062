import { useContext } from "react";
import { Login } from "../../pages/Login";
import { AuthContext } from "./AuthContext";

export const RequireAuth = ({ 
    children, 
    terapeuta = 1, 
    monitor = 1, 
    estagiario = 1 
}: { 
    children: JSX.Element, 
    terapeuta?: number, 
    monitor?: number, 
    estagiario?: number 
}) => {
    const auth = useContext(AuthContext);

    if (!auth.user) {
        // Se não houver um usuário autenticado, redireciona para a página de login
        return <Login />;
    }

    // Verifica se o usuário tem permissão para acessar com base em seu nível
    const IsAdministrador = auth.user.level === 4;
    const IsTerapeuta = auth.user.level === 3;
    const IsMonitor = auth.user.level === 2;
    const IsEstagiario = auth.user.level === 1;
    const IsAdmin = auth.user.admin;
    const IsSupervisor = auth.user.supervisor;

    // Admins e supervisores podem acessar qualquer página
    if (IsAdmin || IsSupervisor || IsAdministrador) {
        return children;
    }

    // Lógica de restrição baseada no nível do usuário
    const isBlocked = 
        (terapeuta === 0 && IsTerapeuta) ||
        (monitor === 0 && IsMonitor) ||
        (estagiario === 0 && IsEstagiario);

    // Se o acesso não estiver bloqueado para o usuário, permite o acesso
    if (!isBlocked) {
        return children;
    }

    // Se o acesso estiver bloqueado para o usuário, redireciona para a página de login
    return <Login />;
};
