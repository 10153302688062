
import { Fragment, useState } from 'react'
import { useContext } from 'react';
import { AuthContext } from '../../contexts/Auth/AuthContext';
import { Dialog, Disclosure, Popover, Transition } from '@headlessui/react'
import {
  HeartIcon,
  Bars3Icon,
  UserPlusIcon,
  ArrowTrendingUpIcon,
  AcademicCapIcon,
  UserCircleIcon,
  CursorArrowRaysIcon,
  ArrowRightStartOnRectangleIcon,
  XMarkIcon,
  UserGroupIcon,
  PlusIcon,
  HomeIcon,
  RectangleStackIcon,
  BuildingOffice2Icon,
  FolderPlusIcon,
  ClipboardDocumentListIcon,
  CalendarDaysIcon,
  ClipboardDocumentIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import logo from '../../images/logo/logo.png';
import { useNavigate } from 'react-router-dom';

const caduser = [
  { name: 'Pacientes', description: 'Inserir / alterar Cadastro de Pacientes', href: '/paciente', icon: UserPlusIcon, auxicon: CursorArrowRaysIcon},
  { name: 'Terapeutas', description: 'Inserir / alterar Cadastro de Terapeutas', href: '/terapeuta', icon: UserPlusIcon, auxicon: HeartIcon },
  { name: 'Auxiliares', description: 'Inserir / alterar Cadastro de Auxiliares', href: '/auxiliar', icon: UserPlusIcon, auxicon: ArrowTrendingUpIcon },
]

const cadgeral = [
  { name: 'Procedimentos', description: 'Inserir / alterar Procedimentos', href: '/procedimento', icon: PlusIcon, auxicon: ClipboardDocumentListIcon },
  { name: 'Atividade', description: 'Inserir / alterar Atividades', href: '/atividade', icon: PlusIcon, auxicon: ClipboardDocumentIcon },
  { name: 'Salas', description: 'Inserir / alterar Cadastro de Salas', href: '/sala', icon: PlusIcon, auxicon: HomeIcon},
  { name: 'Área de Atuação/Formação', description: 'Inserir / alterar Formação', href: '/especialidade', icon: PlusIcon, auxicon: RectangleStackIcon },
  { name: 'Convênios', description: 'Inserir / alterar Cadastro de Convênios', href: '/convenio', icon: PlusIcon, auxicon: BuildingOffice2Icon },
]


// function classNames(...classes:any[]) {
//   return classes.filter(Boolean).join(' ')
// }

export const Header = () => {
  const auth = useContext(AuthContext);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const navigate = useNavigate();

  const handleLogout = async () => {
    await auth.signout();
    window.location.reload(); // Reload the current page
  };

  // Função para lidar com o clique no botão
  const handlePerfil = () => {
    navigate('/Perfil');
    // Aqui você pode adicionar lógica adicional se necessário
  };

  return (
    <header className="bg-gradient-to-r from-blue-500 via-green-500 to-yellow-500">
      <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
        <div className="flex lg:flex-1">
          <a href="/" className="-m-1.5 p-1.5">
            <span className="sr-only">Clínica Praticar</span>
            <img className="h-8 w-auto" src={logo} alt="" />
          </a>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        {auth.user && 
        <Popover.Group className="hidden lg:flex lg:gap-x-2">
          <div className="flex flex-col">
          {/* <div className="flex flex-row gap-x-14">
          <a href="/cobertura" className="flex items-csenter text-xs/10 font-semibold leading-6 text-white">
            <span aria-hidden="true" className="mr-2"><ArrowPathRoundedSquareIcon className='w-6 h-6 text-white'/></span>
            Vincular Cobertura
          </a>
          <a href="/" className="flex items-csenter text-xs/10 font-semibold leading-6 text-white">
            <span aria-hidden="true" className="mr-2"><DocumentTextIcon className='w-6 h-6 text-white'/></span>
            Prontuário Eletrônico
          </a>
          </div> */}
          <div className="flex flex-row ml-2 gap-x-24">
          <Popover className="relative">
            <Popover.Button className="flex items-center gap-x-2 text-xs/10 font-semibold leading-6 text-white">
            <span aria-hidden="true"> <UserGroupIcon className='w-8 h-8 text-white'/></span>Pessoas
              <ChevronDownIcon className="h-5 w-5 flex-none text-white" aria-hidden="true" />
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
                <div className="p-4">
                  {caduser.map((item) => (
                    <div
                      key={item.name}
                      className="group relative flex items-center gap-x-6 rounded-lg p-2 text-xs leading-6 hover:bg-gray-50"
                    >
                      <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                        <item.icon className="h-6 w-6 text-gray-600 group-hover:text-indigo-600" aria-hidden="true" />
                        <item.auxicon className="h-6 w-6 text-gray-600 group-hover:text-indigo-600" aria-hidden="true" />
                      </div>
                      <div className="flex-auto">
                        <a href={item.href} className="block font-semibold text-gray-900">
                          {item.name}
                          <span className="absolute inset-0" />
                        </a>
                        <p className="mt-1 text-gray-600">{item.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
                {/* <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">
                  {callsToAction.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="flex items-center justify-center gap-x-2.5 p-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-100"
                    >
                      <item.icon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                      {item.name}
                    </a>
                  ))}
                </div> */}
              </Popover.Panel>
            </Transition>
          </Popover>
          <Popover className="relative">
            <Popover.Button className="flex items-center gap-x-1 text-xs/10 font-semibold leading-6 text-white">
            <span aria-hidden="true"> <FolderPlusIcon className='w-8 h-8 text-white'/></span>Geral
              <ChevronDownIcon className="h-5 w-5 flex-none text-white" aria-hidden="true" />
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
                <div className="p-4">
                  {cadgeral.map((item) => (
                    <div
                      key={item.name}
                      className="group relative flex items-center gap-x-6 rounded-lg p-2 text-xs leading-6 hover:bg-gray-50"
                    >
                      <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                        <item.icon className="h-6 w-6 text-gray-600 group-hover:text-indigo-600" aria-hidden="true" />
                        <item.auxicon className="h-6 w-6 text-gray-600 group-hover:text-indigo-600" aria-hidden="true" />
                      </div>
                      <div className="flex-auto">
                        <a href={item.href} className="block font-semibold text-gray-900">
                          {item.name}
                          <span className="absolute inset-0" />
                        </a>
                        <p className="mt-1 text-gray-600">{item.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
                {/* <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">
                  {callsToAction.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="flex items-center justify-center gap-x-2.5 p-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-100"
                    >
                      <item.icon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                      {item.name}
                    </a>
                  ))}
                </div> */}
              </Popover.Panel>
            </Transition>
          </Popover>
          <a href="/agenda" className="flex items-center text-xs/10 font-semibold leading-6 text-white">
            <span aria-hidden="true" className="mr-2"><CalendarDaysIcon className='w-8 h-8 text-white'/></span>
            Agendamentos
          </a>
          </div>
          </div>
        </Popover.Group>}
        <div className="hidden lg:flex lg:flex-1 lg:justify-end items-center">
        {auth.user &&   <a href="/perfil" className="text-sm font-semibold leading-6 px-10 text-white">
          <span aria-hidden="true"> <UserCircleIcon className='w-10 h-10'/></span>Perfil
            </a>}
            {auth.user && <button  className="text-sm font-semibold leading-6 text-white"onClick={handleLogout}>
            <span aria-hidden="true"><ArrowRightStartOnRectangleIcon className='w-10 h-10'/></span>Sair
            </button>}
        </div>
      </nav>
      <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-gradient-to-r from-blue-500 via-green-500 to-yellow-500 px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <a href="/" className="-m-1.5 p-1.5">
              <span className="sr-only">Praticar</span>
              <img
                className="h-8 w-auto"
                src={logo}
                alt=""
              />
            </a>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              {auth.user && <div className="space-y-2 py-6">
                <Disclosure as="div" className="-mx-3">
                  {({ open }) =>  (
                    <>
                      <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                        Pessoas
                        {/* <ChevronDownIcon
                          className={classNames(open ? 'rotate-180' : '', 'h-5 w-5 flex-none')}
                          aria-hidden="true"
                        /> */}
                      </Disclosure.Button>
                      <Disclosure.Panel className="mt-2 space-y-2">
                        {/* {[...products, ...callsToAction].map((item) => ( */}
                        {[...caduser].map((item) => (
                          <Disclosure.Button
                            key={item.name}
                            as="a"
                            href={item.href}
                            className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                          >
                            {item.name}
                          </Disclosure.Button>
                        ))}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
                <Disclosure as="div" className="-mx-3">
                  {({ open }) =>  (
                    <>
                      <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                        Geral
                        {/* <ChevronDownIcon
                          className={classNames(open ? 'rotate-180' : '', 'h-5 w-5 flex-none')}
                          aria-hidden="true"
                        /> */}
                      </Disclosure.Button>
                      <Disclosure.Panel className="mt-2 space-y-2">
                        {/* {[...products, ...callsToAction].map((item) => ( */}
                        {[...cadgeral].map((item) => (
                          <Disclosure.Button
                            key={item.name}
                            as="a"
                            href={item.href}
                            className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                          >
                            {item.name}
                          </Disclosure.Button>
                        ))}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
                <a
                  href="/"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Agendamentos
                </a>
              </div>}
              <div className="py-6">
              {auth.user && 
                <button                                   
                  className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-white hover:bg-green-500"
                  onClick={handlePerfil}
                >
                  Perfil
                </button>
                }
              {auth.user && 
                <button                                   
                  className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-white hover:bg-green-500"
                  onClick={handleLogout}
                >
                  Sair
                </button>
                }
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  )
}
