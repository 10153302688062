import { useContext, useState } from "react";
import { PessoaContext } from "../../../../contexts/Pessoa/PessoaContext";
import { Link } from "react-router-dom";
import MessageBox from "../../../../components/Utils/MessageBox";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline"
import { AuthContext } from "../../../../contexts/Auth/AuthContext";

export const AdicionaMonitor = () => {
    const { monitorActions } = useContext(PessoaContext);
    const [nome, setNome] = useState<string | undefined>('');
    const [escolaridade, setEscolaridade] = useState<string>('Fundamental');
    const [matricula, setMatricula] = useState<string | undefined>('');
    const [cpf, setCpf] = useState<string | undefined>('');

    const [dtInicio, setDtInicio] = useState<string>('');
    const [dtFim, setDtFim] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [telefone, setTelefone] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const [showMessage, setShowMessage] = useState<boolean>(false);
    const [cpfError, setCpfError] = useState<boolean>(false);

    const [showIcon, setShowIcon] = useState<boolean>(false);  
    const [horarios, setHorarios] = useState<{ [key: string]: { entrada: string; saida: string }[] }>({
      segunda: [],
      terca: [],
      quarta: [],
      quinta: [],
      sexta: [],
      sabado: [],
      domingo: [],
  });
  const auth = useContext(AuthContext);
  const idUser = auth.user?.id_user;
  const level = auth.user?.level;
  const IsSupervisor = auth.user?.supervisor;
  const IsAdministrador = auth.user?.level === 4;
  const IsTerapeuta = auth.user?.level === 3;
  const IsMonitor = auth.user?.level === 2;
  const IsAdmin = auth.user?.admin;
  const [permissao, setPermissao] = useState({
    semPermissaoEspecial: true, // Opção especial
    administrador: false,
    supervisor: false,
  });

  const handlePermissaoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;

    // Lógica para desabilitar/limpar outras opções ao marcar "Sem Permissões Especiais"
    if (name === "semPermissaoEspecial" && checked) {
        setPermissao({
            semPermissaoEspecial: true,
            administrador: false,
            supervisor: false,
        });
    } else if (name === "semPermissaoEspecial" && !checked) {
        setPermissao({
            ...permissao,
            semPermissaoEspecial: false,
        });
    } else {
        // Se outra permissão for marcada, desmarca "Sem Permissões Especiais"
        setPermissao({
            ...permissao,
            [name]: checked,
            semPermissaoEspecial: false,
        });
    }
};


  let totalGeral = 0;

  const converterParaMinutos = (horaString: string): number => {
    const [horas, minutos] = horaString.split(':').map(Number);

    return horas * 60 + minutos;
  };

  // Função para converter minutos para "HH:MM"
  const converterParaHoras = (minutosTotais: number): string => {
    const horas = Math.floor(minutosTotais / 60);
    const minutos = minutosTotais % 60;
    return `${String(horas).padStart(2, '0')}:${String(minutos).padStart(2, '0')}`;
  };


  const calcularSomaHorasPorDia = () => {
    const somaPorDia: { [key: string]: string } = {};
        Object.entries(horarios).forEach(([dia, horariosDia]) => {
            let totalHoras = 0;
            let totalMinutos = 0;

            horariosDia.forEach(horario => {
                // Verifica se os campos de entrada estão preenchidos
                if (horario.entrada && horario.saida) {
                    const horaEntrada = horario.entrada.split(":").map(Number);
                    const horaSaida = horario.saida.split(":").map(Number);

                    // Calcula a diferença em horas e minutos
                    let diffHoras = horaSaida[0] - horaEntrada[0];
                    let diffMinutos = horaSaida[1] - horaEntrada[1];

                    // Garante que os minutos sejam positivos
                    if (diffMinutos < 0) {
                        diffHoras--;
                        diffMinutos += 60;
                    }

                    totalHoras += diffHoras;
                    totalMinutos += diffMinutos;
                }
            });

            // Converte os minutos extras em horas
            totalHoras += Math.floor(totalMinutos / 60);
            totalMinutos %= 60;

            // Formata o tempo total para uma string legível
            const tempoFormatado = `${totalHoras.toString().padStart(2, '0')}:${totalMinutos.toString().padStart(2, '0')}`;

            somaPorDia[dia] = tempoFormatado;
        });

        return somaPorDia;
    };


    const handleSubmit = async () => {
      if (nome && escolaridade && dtInicio && email && telefone && idUser) {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        const cpfString = cpf || ''; // Define um valor padrão vazio se cpf for undefined

            if (!validarCPF(cpfString)) {
                setMessage("CPF inválido");

                setShowIcon(true);
                setShowMessage(true);
                setCpfError(true);
                return;
            } else {
                setCpfError(false);
            }

          if (!emailPattern.test(email)) {
          setMessage("Por favor, insira um e-mail válido.");
          setShowIcon(true);
          setShowMessage(true);
          return;
        }
    
        const phonePattern = /^\(?\d{2}\)?\s?\d{4,5}-?\d{4}$/; // Expressão regular para telefone fixo e celular
        if (!phonePattern.test(telefone)) {
          setMessage("Por favor, insira um número de telefone válido.");
          setShowIcon(true);
          setShowMessage(true);
          return;
        }
    
        const idUserAltered = await monitorActions.add({
          id_monitor: null,
          nome: nome,
          escolaridade: escolaridade,
          matricula: matricula,
          cpf: cpf,

          dt_inicio: dtInicio,
          dt_fim: dtFim,
          email: email,
          telefone: telefone,
          horarios: horarios,
          id_user: idUser,
          permissao: permissao,
        });
        if (idUserAltered) {
          setMessage('Dados atualizados com sucesso');
          // Limpe os campos
          setNome('');
                      setCpf('');

          setEscolaridade('Fundamental');
                   setMatricula('');

            setDtInicio('');
          setDtFim('');
          setEmail('');
          setTelefone('');
          setHorarios({
            segunda: [],
            terca: [],
            quarta: [],
            quinta: [],
            sexta: [],
            sabado: [],
            domingo: [],
          });
          setPermissao({
            semPermissaoEspecial: true,
            administrador: false,
            supervisor: false,
          });
        } else {
          setMessage("Não foi possível inserir os dados.");
          setShowIcon(true);
        }
      } else {
        setMessage("Por favor, preencha todos os campos.");
        setShowIcon(true);
      }
      setShowMessage(true);
    };

  const handleCloseMessage = () => {
    setShowMessage(false);
  };

  // const handleDiaChange = (dia: string, index: number, campo: string, valor: string) => {
  //   setHorarios(prevHorarios => ({
  //       ...prevHorarios,
  //       [dia]: prevHorarios[dia].map((horario, i) => 
  //           i === index ? { ...horario, [campo]: valor } : horario
  //       )
  //   }));
  // };

  const handleDiaChange = (dia: string, index: number, campo: string, valor: string) => {
    // Verifica se o campo é 'saida' e se o horário de saída é maior que o de entrada
   
    // Atualiza o estado dos horários apenas se não houver erro
    setHorarios(prevHorarios => ({
        ...prevHorarios,
        [dia]: prevHorarios[dia].map((horario, i) => 
            i === index ? { ...horario, [campo]: valor } : horario
        )
    }));
};


  const handleAddHorario = (dia: string) => {
    setHorarios(prevHorarios => ({
        ...prevHorarios,
        [dia]: [...prevHorarios[dia], { entrada: '00:00', saida: '00:00' }] // Inicializa os horários como 00:00
    }));
  };

  const handleRemoveHorario = (dia: string, index: number) => {
      setHorarios(prevHorarios => ({
          ...prevHorarios,
          [dia]: prevHorarios[dia].filter((_, i) => i !== index)
      }));
  };

    // Função para formatar o número de telefone
    const formatarTelefone = (telefone: string) => {
      if (telefone.length === 10) {
        // Formato para telefone fixo
        return `(${telefone.substring(0, 2)}) ${telefone.substring(2, 6)}-${telefone.substring(6)}`;
      } else if (telefone.length === 11) {
        // Formato para celular
        return `(${telefone.substring(0, 2)}) ${telefone.substring(2, 7)}-${telefone.substring(7)}`;
      }
      return telefone; // Retorna o número sem formatação se não corresponder aos padrões
    };
  
        // Função para atualizar o estado do telefone formatado
    const handleTelefoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const rawTelefone = e.target.value.replace(/\D/g, ''); // Remove caracteres não numéricos
      setTelefone(formatarTelefone(rawTelefone));
    };

function validarCPF(cpf: string): boolean {
  if (cpf === '') {
            return true;
        } else {
            cpf = cpf.replace(/\D/g, ''); // Remove caracteres não numéricos
            if (cpf.length !== 11 || /^(\d)\1{10}$/.test(cpf)) {
                return false; // CPF inválido
            }
            let soma = 0;
            let resto;
            for (let i = 1; i <= 9; i++) {
                soma += parseInt(cpf.substring(i - 1, i)) * (11 - i);
            }
            resto = (soma * 10) % 11;
            if (resto === 10 || resto === 11) {
                resto = 0;
            }
            if (resto !== parseInt(cpf.charAt(9))) {
                return false;
            }
            soma = 0;
            for (let i = 1; i <= 10; i++) {
                soma += parseInt(cpf.substring(i - 1, i)) * (12 - i);
            }
            resto = (soma * 10) % 11;
            if (resto === 10 || resto === 11) {
                resto = 0;
            }
            if (resto !== parseInt(cpf.charAt(10))) {
                return false;
            }
            return true;
        }
    }

  return (
    <div className="min-h-screen flex items-center justify-center pt-12">
    {showMessage && <MessageBox message={message}
    onClose={() => {
    setShowIcon(false);
    handleCloseMessage();
    }}  
    icon={showIcon ? 
    <ExclamationCircleIcon className="w-10 h-10 mx-auto text-red-500"/> : null}
    />}
      <form className="w-full max-w-md">
      <div className="space-y-12">
        <div className="border-b border-gray-900/10 pb-12">
        <h2 className="text-3xl font-bold leading-8 text-gray-900 border-b-4 border-indigo-500 pb-2">Cadastrar Auxiliares</h2>
   
            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    <div className="col-span-full">
              <label htmlFor="matricula" className="block text-sm font-medium leading-6 text-gray-900">
                Matrícula
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="matricula"
                  id="matricula"
                  autoComplete="given-name"
                  value={matricula}
                  className="text-center block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  onChange={(e) => setMatricula(e.target.value)}
                  required
                  />
              </div>
            </div>

                <div className="col-span-full">
              <label htmlFor="nome" className="block text-sm font-medium leading-6 text-gray-900">
                Nome
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="nome"
                  id="nome"
                  autoComplete="given-name"
                  value={nome}
                  className="text-center block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  onChange={(e) => setNome(e.target.value)}
                  required
                  />
              </div>
            </div>
            <div className="col-span-full">
                                <label htmlFor="cpf" className="block text-sm font-medium leading-6 text-gray-900">
                                CPF 
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        id="cpf"
                                        value={cpf}
                                        onChange={(e) => setCpf(e.target.value)}
   className={`text-center block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${cpfError ? 'ring-red-500 border-red-500' : ''}`}
      maxLength={11}  // Define o comprimento máximo para 11 dígitos                                        
                                    />
                                </div>
                            </div>
            <div className="col-span-full">
              <label htmlFor="escolaridade" className="block text-sm font-medium leading-6 text-gray-900">
                Escolaridade
              </label>
              <div className="mt-2">
                <select
                  id="escolaridade"
                  name="escolaridade"
                  value={escolaridade}
                  onChange={(e) => setEscolaridade(e.target.value)}
                  className="text-center block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  required
                >
                  <option value="Fundamental">Fundamental</option>
                  <option value="Médio">Médio</option>
                  <option value="Superior (Graduação)">Superior (Graduação)</option>
                  <option value="Pós-graduação">Pós-graduação</option>
                  <option value="Mestrado">Mestrado</option>
                  <option value="Doutorado">Doutorado</option>
                </select>
              </div>
            </div>

            <div className="col-span-full">
              <label htmlFor="dtinicio" className="block text-sm font-medium leading-6 text-gray-900">
                Data Início
              </label>
              <div className="mt-2">
                <input
                  type="date"
                  name="dtinicio"
                  id="dtinicio"
                  autoComplete="dtinicio"
                  value={dtInicio}
                  className="text-center block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  onChange={(e) => setDtInicio(e.target.value)}
                  required
                />
              </div>
            </div>

            {IsAdministrador===true? <div className="col-span-full">
              <label htmlFor="dtfim" className="block text-sm font-medium leading-6 text-gray-900">
                Data Fim
              </label>
              <div className="mt-2">
                <input
                  type="date"
                  name="dtfim"
                  id="dtfim"
                  autoComplete="dtfim"
                  value={dtFim}
                  className="text-center block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  onChange={(e) => setDtFim(e.target.value)}
                />
              </div>
            </div>: ''}

            <div className="col-span-full">
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                Email
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="email"
                  id="email"
                  autoComplete="given-name"
                  value={email}
                  className="text-center block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  />
              </div>
            </div>

            <div className="col-span-full">
              <label htmlFor="telefone" className="block text-sm font-medium leading-6 text-gray-900">
                Telefone
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="telefone"
                  id="telefone"
                  autoComplete="given-name"
                  value={telefone}
                  className="text-center block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  maxLength={15} // Definindo o máximo de caracteres permitidos
                  onChange={handleTelefoneChange} // Usando a função handleTelefoneChange
                  required
                  />
              </div>
            </div>
            {IsAdministrador===true? <div className="col-span-full">
  <label className="block text-sm font-medium leading-6 text-gray-900">
    Permissões
  </label>
  <div className="mt-4 p-4 border-2 border-gray-300 rounded-lg shadow-sm space-y-4 flex flex-col items-center">
    {/* Sem Permissões Especiais */}
    <div className="flex items-center bg-gray-100 p-3 rounded-md shadow-sm">
      <input
        type="checkbox"
        name="semPermissaoEspecial"
        id="semPermissaoEspecial"
        checked={permissao.semPermissaoEspecial}
        onChange={handlePermissaoChange}
        className="mr-3 h-5 w-5 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
      />
      <label
        htmlFor="semPermissaoEspecial"
        className="text-gray-800 font-semibold"
      >
        Sem Permissões Especiais
      </label>
    </div>

    {/* Demais permissões */}
    <div className="flex justify-center space-x-8">
      <div className="flex items-center">
        <input
          type="checkbox"
          name="administrador"
          id="administrador"
          checked={permissao.administrador}
          onChange={handlePermissaoChange}
          disabled={permissao.semPermissaoEspecial} // Desabilita se a opção especial estiver marcada
          className="mr-2 h-4 w-4 rounded text-indigo-600 focus:ring-indigo-500"
        />
        <label
          htmlFor="administrador"
          className={`text-gray-900 ${
            permissao.semPermissaoEspecial ? "text-gray-400" : ""
          }`}
        >
          Administrador
        </label>
      </div>
      <div className="flex items-center">
        <input
          type="checkbox"
          name="supervisor"
          id="supervisor"
          checked={permissao.supervisor}
          onChange={handlePermissaoChange}
          disabled={permissao.semPermissaoEspecial} // Desabilita se a opção especial estiver marcada
          className="mr-2 h-4 w-4 rounded text-indigo-600 focus:ring-indigo-500"
        />
        <label
          htmlFor="supervisor"
          className={`text-gray-900 ${
            permissao.semPermissaoEspecial ? "text-gray-400" : ""
          }`}
        >
          Supervisor
        </label>
      </div>
    </div>
  </div>
</div>
: ''}
            <div className="col-span-full">
            {Object.entries(horarios).map(([dia, horariosDia]) => (
                            <div key={dia}>
                                <h3>{dia}</h3>
                                {horariosDia.map((horario, index) => (
                                    <div key={index} className="flex items-center space-x-16 py-4">
                                        <div>
                                            <label className="block text-sm font-medium leading-6 text-gray-900">Entrada</label>
                                            <input
                                                type="time"
                                                value={horario.entrada}
                                                onChange={(e) => handleDiaChange(dia, index, 'entrada', e.target.value)}
                                                className="mt-1 block w-28 rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            />
                                        </div>
                                        <div>
                                            <label className="block text-sm font-medium leading-6 text-gray-900">Saída</label>
                                            <input
                                                type="time"
                                                value={horario.saida}
                                                onChange={(e) => handleDiaChange(dia, index, 'saida', e.target.value)}
                                                className="mt-1 block w-28 rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            />
                                        </div>
                                        <button type="button" onClick={() => handleRemoveHorario(dia, index)} className="text-sm font-medium text-red-600 hover:text-red-500 focus:outline-none focus:underline">Remover</button>
                                    </div>
                                ))}
                                <button type="button" onClick={() => handleAddHorario(dia)} className="text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline py-2">Adicionar Horário</button>
                            </div>
                        ))}
            </div>
          </div>
          <div className="mt-6 bg-gray-100 rounded-lg p-4 shadow-md">
              <h3 className="text-xl font-semibold mb-2">Somatório das horas:</h3>
              <ul className="divide-y divide-gray-200">
                  {Object.entries(calcularSomaHorasPorDia()).map(([dia, totalHoras]) => {
                      const minutos = converterParaMinutos(totalHoras);
                      totalGeral += minutos;  // Soma o total de horas em cada iteração
                      return (
                          <li key={dia} className="py-2 flex justify-between">
                              <span>{dia}:</span>
                              <span className="font-semibold">{totalHoras}</span>
                          </li>
                      );
                  })}
                  <li key="total" className="py-2 flex justify-between">
                      <span>Total:</span>
                      <span className="font-semibold">{converterParaHoras(totalGeral)}</span>
                  </li>
              </ul>
          </div>
        </div>
      </div>
      <div className="mt-6 flex items-center justify-end gap-x-6 pb-12">
          <Link to="/auxiliar" className="text-indigo-600 hover:text-indigo-900">
            Voltar
          </Link>
          <button
            type="button"
            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={handleSubmit}
          >
            Salvar
          </button>
        </div>
    </form>

</div>

    );
}
