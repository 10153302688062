import { useContext, useEffect, useState } from "react";
import { GeralContext } from "../../../../contexts/Geral/GeralContext";
import { Link, useLocation } from "react-router-dom";
import MessageBox from "../../../../components/Utils/MessageBox";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { PessoaContext } from "../../../../contexts/Pessoa/PessoaContext";
import { AuthContext } from "../../../../contexts/Auth/AuthContext";

interface ConvenioItem {
  codconvenio: string;
  convenio: string;
}

interface Responsavel {
  nome: string;
  filiacao: string;
  telefone: string;
  cpf: string;
  resp: boolean;
}

interface CidItem {
  cid: string;
  diagnostico: string;
}

export const EditaPaciente = () => {
    const { pacActions } = useContext(PessoaContext);
    const { convenioActions } = useContext(GeralContext);
    const {cidActions} = useContext(GeralContext);
    const [id_pac, setIdPac] = useState<string | undefined>();
    const [id_seq, setIdSeq] = useState<string | undefined>();
  
    const [nome, setNome] = useState<string | undefined>('');
    const [cpf, setCpf] = useState<string | undefined>('');
    const [cpfError, setCpfError] = useState<boolean>(false);

    const [dtNasc, setdtNasc] = useState<string>('');
    const [genero, setGenero] = useState<string>('');
    const [medResp, setMedResp] = useState<string>('');
    const [CRM, setCRM] = useState<string>('');
    const [telMed, setTelMed] = useState<string>('');
    const [responsaveis, setResponsaveis] = useState<Responsavel[]>([]); // Alteração aqui
    const [dias, setDias] = useState<number>(15);
    const [cep, setCep] = useState<string>('');
    const [estado, setEstado] = useState<string>('');
    const [cidade, setCidade] = useState<string>('');
    const [bairro, setBairro] = useState<string>('');
    const [logradouro, setLogradouro] = useState<string>('');
    const [numeral, setNumeral] = useState<string>('');
    const [complemento, setComplemento] = useState<string>('');
    const [nCartao, setNCartao] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const [showMessage, setShowMessage] = useState<boolean>(false);
    const [showIcon, setShowIcon] = useState<boolean>(false);
    const [cids, setCids] = useState<CidItem[]>([]);
    const [isAutomatic, setIsAutomatic] = useState(true);
    const [convenios, setConvenios] = useState<ConvenioItem[]>([]);
    const [selectedConv, setSelectedConv] = useState<{ codconvenio: string, ncartao: string }[]>([]);
    const [filter, setFilter] = useState<string>('');
    const location = useLocation();
    const selectedItem = location.state?.selectedItem;
    const auth = useContext(AuthContext);
    const idUser = auth.user?.id_user; 
    const IsAdministrador = auth.user?.level === 4;
    const IsSupervisor = auth.user?.supervisor;
    const IsTerapeuta = auth.user?.level === 3;
    const IsMonitor = auth.user?.level === 2;
    const IsEstagiario = auth.user?.level === 1;
    const IsAdmin = auth.user?.admin;

    const level = auth.user?.level; 

  useEffect(() => {
    console.log(selectedItem);
    if (selectedItem.id_pac !== undefined) {
      setIdPac(selectedItem.id_pac);
    }
    if (selectedItem.id_seq !== undefined) {
      setIdSeq(selectedItem.id_seq);
    }
    if (selectedItem.cpf !== undefined) {
      setCpf(selectedItem.cpf);
    }

    if (selectedItem.nome !== undefined) {
      setNome(selectedItem.nome);
    }
    if (selectedItem.dt_nasc !== undefined) {
      const parsedDate = new Date(selectedItem.dt_nasc).toISOString().split('T')[0];
      setdtNasc(parsedDate);
    }
    if (selectedItem.genero !== undefined) {
      setGenero(selectedItem.genero);
    }
    if (selectedItem.med_resp !== undefined) {
      setMedResp(selectedItem.med_resp);
    }
    if (selectedItem.crm !== undefined) {
      setCRM(selectedItem.crm);
    }
    if (selectedItem.tel_med !== undefined) {
      setTelMed(selectedItem.tel_med);
    }
    if (selectedItem.responsaveis !== undefined) {
      setResponsaveis(selectedItem.responsaveis);
    }
    if (selectedItem.n_cartao !== undefined) {
      setNCartao(String(selectedItem.n_cartao));
    }
    if (selectedItem.convenios_selecionados !== undefined) {
      setSelectedConv(selectedItem.convenios_selecionados);
    }
    if (selectedItem.cep !== undefined) {
      setCep(selectedItem.cep);
    }
    if (selectedItem.estado !== undefined) {
      setEstado(selectedItem.estado);
    }
    if (selectedItem.cidade !== undefined) {
      setCidade(selectedItem.cidade);
    }
    if (selectedItem.bairro !== undefined) {
      setBairro(selectedItem.bairro);
    }
    if (selectedItem.logradouro !== undefined) {
      setLogradouro(selectedItem.logradouro);
    }
    if (selectedItem.numeral !== undefined) {
      setNumeral(selectedItem.numeral);
    }
    if (selectedItem.complemento !== undefined) {
      setComplemento(selectedItem.complemento);
    }
  }, [selectedItem]);

    useEffect(() => {
      const fetchCidData = async () => {
        try {
          const cidData = await cidActions.fill();
          setCids(cidData);
        } catch (error) {
          console.error("Erro ao buscar dados dos CIDs:", error);
        }
      };
      fetchCidData();
    }, [cidActions]);
  
    useEffect(() => {
      const fetchConvenioData = async () => {
        try {
          const convenioData = await convenioActions.fill();
          setConvenios(convenioData);
        } catch (error) {
          console.error("Erro ao buscar dados dos convenios:", error);
        }
      };
      fetchConvenioData();
    }, [convenioActions]);
    
    const handleSubmit = async () => {
            const cpfString = cpf || ''; // Define um valor padrão vazio se cpf for undefined

      if (!validarCPF(cpfString)) {
          setMessage("CPF inválido");

          setShowIcon(true);
          setShowMessage(true);
          setCpfError(true);
          return;
      } else {
          setCpfError(false);
      }
    
      if (id_pac && id_seq && nome  && idUser) {
        const idUserAltered = await pacActions.edit({
          id_pac: id_pac,
          id_seq: id_seq,
          nome: nome,
          cpf: cpf,
          dt_nasc: dtNasc,
          genero: genero,
          responsaveis: responsaveis,
          dias: dias,
          cep: cep,
          estado: estado,
          cidade: cidade,
          bairro: bairro,
          logradouro: logradouro,
          numeral: numeral,
          complemento: complemento,
          id_user: idUser,
          med_resp: medResp,
          crm: CRM,
          tel_med: telMed,
          convenios_selecionados: selectedConv // Incluindo a lista de convênios selecionados
        });
        
        if (idUserAltered) {
          setMessage('Dados atualizados com sucesso');
        } else {
          setMessage("Não foi possível inserir os dados.");
          setShowIcon(true);
        }
      } else {
        setMessage("Por favor, preencha todos os campos.");
        setShowIcon(true);
      }
      setShowMessage(true);
    }
    

    const handleCloseMessage = () => {
      setShowMessage(false);
      if (!showIcon) {
        window.location.href = '/paciente'; // Redireciona apenas se não houver ícone de erro
      }
    };

    // Função para formatar o número de telefone
    const formatarTelefone = (telefone: string) => {
      if (telefone.length === 10) {
        // Formato para telefone fixo
        return `(${telefone.substring(0, 2)}) ${telefone.substring(2, 6)}-${telefone.substring(6)}`;
      } else if (telefone.length === 11) {
        // Formato para celular
        return `(${telefone.substring(0, 2)}) ${telefone.substring(2, 7)}-${telefone.substring(7)}`;
      }
      return telefone; // Retorna o número sem formatação se não corresponder aos padrões
    };

    // Função para atualizar o estado do telefone formatado
    const handleTelefoneChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
      const rawTelefone = e.target.value.replace(/\D/g, ''); // Remove caracteres não numéricos
      const updatedResponsaveis = [...responsaveis]; // Crie uma cópia da matriz de responsáveis
      updatedResponsaveis[index] = { ...updatedResponsaveis[index], telefone: formatarTelefone(rawTelefone) }; // Atualize o telefone do responsável específico
      setResponsaveis(updatedResponsaveis); // Defina o estado responsaveis com a matriz atualizada
    };

    const handleTelefoneMedChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const rawTelefone = e.target.value.replace(/\D/g, ''); // Remove caracteres não numéricos
      setTelMed(formatarTelefone(rawTelefone));
    };

    function validarCPF(cpf: string): boolean {
      if (cpf === '') {
              return true;
          } else {
              cpf = cpf.replace(/\D/g, ''); // Remove caracteres não numéricos
              if (cpf.length !== 11 || /^(\d)\1{10}$/.test(cpf)) {
                  return false; // CPF inválido
              }
              let soma = 0;
              let resto;
              for (let i = 1; i <= 9; i++) {
                  soma += parseInt(cpf.substring(i - 1, i)) * (11 - i);
              }
              resto = (soma * 10) % 11;
              if (resto === 10 || resto === 11) {
                  resto = 0;
              }
              if (resto !== parseInt(cpf.charAt(9))) {
                  return false;
              }
              soma = 0;
              for (let i = 1; i <= 10; i++) {
                  soma += parseInt(cpf.substring(i - 1, i)) * (12 - i);
              }
              resto = (soma * 10) % 11;
              if (resto === 10 || resto === 11) {
                  resto = 0;
              }
              if (resto !== parseInt(cpf.charAt(10))) {
                  return false;
              }
              return true;
          }
      }

    const handleCpfChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const novoCpf = e.target.value;
        const updatedResponsaveis = [...responsaveis];
        
        if (novoCpf.length <= 11) {
            updatedResponsaveis[index].cpf = novoCpf;
            setResponsaveis(updatedResponsaveis);

            if (novoCpf.length === 11) {
                const cpfValido = validarCPF(novoCpf);

                if (!cpfValido) {
                    setMessage(`CPF inválido para o responsável ${index + 1}`);
                    setShowIcon(true);
                    setShowMessage(true);
                    console.log(`CPF inválido para o responsável ${index + 1}`);
                } else {
                    setMessage(""); // Limpa a mensagem de erro se o CPF for válido
                    setShowIcon(false);
                    setShowMessage(false);
                }
            } else {
                setMessage(""); // Limpa a mensagem de erro quando o CPF não tem 11 dígitos
                setShowIcon(false);
                setShowMessage(false);
            }
        }
    };

    useEffect(() => {
      const fetchConvenioData = async () => {
        try {
          const convenioData = await convenioActions.fill();
          setConvenios(convenioData);

          // Preencher os convênios selecionados baseados no `selectedItem`
          if (selectedItem.convenios_selecionados) {
            setSelectedConv(selectedItem.convenios_selecionados);
          }
        } catch (error) {
          console.error("Erro ao buscar dados dos convênios:", error);
        }
      };
      fetchConvenioData();
    }, [convenioActions, selectedItem]);
    
    const handleCheckboxChangeConv = (codconvenio: string) => {
      setSelectedConv((prevSelectedConv) => {
        if (prevSelectedConv.some(item => item.codconvenio === codconvenio)) {
          return prevSelectedConv.filter(item => item.codconvenio !== codconvenio);
        } else {
          return [...prevSelectedConv, { codconvenio, ncartao: '' }];
        }
      });
    };
    
    const handleNCartaoChange = (codconvenio: string, ncartao: string) => {
      setSelectedConv((prevSelectedConv) => {
        return prevSelectedConv.map(item => 
          item.codconvenio === codconvenio ? { ...item, ncartao } : item
        );
      });
    };
    
    const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setFilter(e.target.value.toLowerCase());
    };
    
    const filteredConvenios = convenios.filter((conv) => {
      const codconvenioStr = String(conv.convenio).toLowerCase();
      return codconvenioStr.includes(filter);
    });
    
    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setIsAutomatic(e.target.checked);
    };
  
    
  
  return (
    <div className="min-h-screen flex items-center justify-center pt-12">
      {showMessage && <MessageBox message={message}
      onClose={() => {
      setShowIcon(false);
      handleCloseMessage();
      }}  
      icon={showIcon ? 
      <ExclamationCircleIcon className="w-10 h-10 mx-auto text-red-500"/> : null}
      />}      <form className="w-full max-w-md">
      <div className="space-y-12">
        <div className="border-b border-gray-900/10 pb-12">
        <h2 className="text-3xl font-bold leading-8 text-gray-900 border-b-4 border-indigo-500 pb-2">Editar Paciente</h2>
          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
             <div className="col-span-full">
                <label htmlFor="id_seq" className="block text-sm font-medium leading-6 text-gray-900">
                  ID do Paciente
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="id_seq"
                    id="id_seq"
                    autoComplete="given-name"
                    value={id_seq}
                    className="text-center block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={(e) => setIdSeq(e.target.value)}
                    />
                </div>
              </div>
              <div className="col-span-full">
                <label htmlFor="nome" className="block text-sm font-medium leading-6 text-gray-900">
                  Nome
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="nome"
                    id="nome"
                    autoComplete="given-name"
                    value={nome}
                    className="text-center block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={(e) => setNome(e.target.value)}
                    />
                </div>
              </div>
              <div className="col-span-full">
                  <label htmlFor="cpf" className="block text-sm font-medium leading-6 text-gray-900">
                  CPF (caso possua, senão deixar em branco)
                  </label>
                  <div className="mt-2">
                      <input
                          type="text"
                          id="cpf"
                          value={cpf}
                          onChange={(e) => setCpf(e.target.value)}
                          className={`text-center block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${cpfError ? 'ring-red-500 border-red-500' : ''}`}
                          maxLength={11}  // Define o comprimento máximo para 11 dígitos                                        
                      />
                  </div>
              </div>
              <div className="col-span-full">
                <label htmlFor="dtnasc" className="block text-sm font-medium leading-6 text-gray-900">
                  Data de Nascimento
                </label>
                <div className="mt-2">
                  <input
                    type="date"
                    name="dtnasc"
                    id="dtnasc"
                    autoComplete="dtnasc"
                    value={dtNasc}
                    className="text-center block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={(e) => setdtNasc(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-span-full">
                  <label className="ms-2 text-sm font-medium text-black-900 dark:text-black-300">
                      <input
                      type="radio"
                      value="M"
                      checked={genero === "M"}
                      onChange={(e) => setGenero(e.target.value)}
                      className="w-4 h-4 text-blue-600 bg-black-100 border-black-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-black-800 focus:ring-2 dark:bg-black-700 dark:border-black-600"
                      />
                      <span className="ml-2">Masculino</span>
                  </label>
                  <label className="ms-2 text-sm font-medium text-black-900 dark:text-black-300">
                      <input
                      type="radio"
                      value="F"
                      checked={genero === "F"}
                      onChange={(e) => setGenero(e.target.value)}
                      className="w-4 h-4 text-blue-600 bg-black-100 border-black-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-black-800 focus:ring-2 dark:bg-black-700 dark:border-black-600"
                      />
                      <span className="ml-2">Feminino</span>
                  </label>
              </div>

              <div className="col-span-full">
                <label htmlFor="medresp" className="block text-sm font-medium leading-6 text-gray-900">
                  Médico Responsável
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="medresp"
                    id="medresp"
                    autoComplete="given-name"
                    value={medResp}
                    className="text-center block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={(e) => setMedResp(e.target.value)}
                    required
                    />
                </div>
              </div>
              <div className="col-span-full">
                <label htmlFor="crm" className="block text-sm font-medium leading-6 text-gray-900">
                  CRM:
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="crm"
                    id="crm"
                    autoComplete="given-name"
                    value={CRM}
                    className="text-center block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={(e) => setCRM(e.target.value)}
                    required
                    />
                </div>
              </div>
              <div className="col-span-full">
                <label htmlFor="telmed" className="block text-sm font-medium leading-6 text-gray-900">
                  Contato:
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="telmed"
                    id="telmed"
                    autoComplete="given-name"
                    value={telMed}
                    className="text-center block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    maxLength={15} // Definindo o máximo de caracteres permitidos
                    onChange={handleTelefoneMedChange} // Usando a função handleTelefoneChange
                    required
                    />
                </div>
              </div>
              {/* Início da seção para múltiplos responsáveis */}
              {responsaveis.map((responsavel, index) => (
                <div key={index} className="col-span-full">
                  <label htmlFor={`responsavel-${index}`} className="block text-sm font-medium leading-6 text-gray-900">
                    Responsável #{index + 1}
                  </label>
                  <div className="mt-2 flex gap-x-2">
                    <input
                      type="text"
                      id={`responsavel-${index}`}
                      value={responsavel.nome}
                      onChange={(e) => {
                        const updatedResponsaveis = [...responsaveis];
                        updatedResponsaveis[index].nome = e.target.value;
                        setResponsaveis(updatedResponsaveis);
                      }}
                      className="text-center block flex-1 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      placeholder="Nome"
                      required
                    />
                    <input
                      type="text"
                      placeholder="Filiação"
                      value={responsavel.filiacao}
                      onChange={(e) => {
                        const updatedResponsaveis = [...responsaveis];
                        updatedResponsaveis[index].filiacao = e.target.value;
                        setResponsaveis(updatedResponsaveis);
                      }}
                      className="text-center block flex-1 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    <input
                      type="text"
                      placeholder="Telefone"
                      value={responsavel.telefone}
                      onChange={(e) => handleTelefoneChange(e, index)} // Alteração aqui
                      className="text-center block flex-1 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  <input
                      type="text"
                      placeholder="CPF"
                      value={responsavel.cpf}
                      onChange={(e) => handleCpfChange(e, index)}
                      className="text-center block flex-1 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      maxLength={11} // Limita a entrada para 11 dígitos
                    />
                  <label className="flex items-center mt-2">
                      <input
                        type="checkbox"
                        checked={responsavel.resp}
                        onChange={(e) => {
                          const updatedResponsaveis = [...responsaveis];
                          updatedResponsaveis[index].resp = e.target.checked;
                          setResponsaveis(updatedResponsaveis);
                        }}
                        className="mr-1"
                      />
                      <span className="text-sm font-medium text-gray-900">Contato Principal</span>
                    </label>        
                    <button
                      type="button"
                      onClick={() => {
                        const updatedResponsaveis = [...responsaveis];
                        updatedResponsaveis.splice(index, 1);
                        setResponsaveis(updatedResponsaveis);
                      }}
                      className="text-red-500 hover:text-red-700 focus:outline-none"
                    >
                      Remover
                    </button>
                  </div>
                </div>
              ))}
              <div className="col-span-full">
                <button
                  type="button"
                  onClick={() => {
                    setResponsaveis([...responsaveis, { nome: '', filiacao: '', telefone: '', cpf: '', resp: false }]);
                  }}
                  className="text-indigo-600 hover:text-indigo-900 focus:outline-none"
                >
                  Adicionar Filiação
                </button>
              </div>
              {/* Fim da seção para múltiplos responsáveis */}

            {/* Início da seção de convênios com checkboxes */}
            <div className="col-span-full">
              <label htmlFor="convenio" className="block text-sm font-medium leading-6 text-gray-900">
                Convênio:
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  id="filter"
                  value={filter}
                  onChange={handleFilterChange}
                  className="text-center block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder="Filtrar convênios"
                />
              </div>
            </div>
    
            <div className="col-span-full">
                {filteredConvenios.map((convenio) => (
                  <div key={convenio.codconvenio} className="flex items-center mb-2">
                    <input
                      type="checkbox"
                      value={convenio.codconvenio}
                      checked={selectedConv.some(item => item.codconvenio === convenio.codconvenio)}
                      onChange={() => handleCheckboxChangeConv(convenio.codconvenio)}
                      className="mr-2"
                    />
                    <span>{convenio.convenio}</span>
                    {selectedConv.some(item => item.codconvenio === convenio.codconvenio) && (
                      <input
                        type="text"
                        placeholder="Número do Cartão"
                        value={selectedConv.find(item => item.codconvenio === convenio.codconvenio)?.ncartao || ''}
                        onChange={(e) => handleNCartaoChange(convenio.codconvenio, e.target.value)}
                        className="ml-2 p-1 border rounded-md"
                      />
                    )}
                  </div>
                ))}
              </div>
                {/* Fim da seção de convênios com checkboxes */}
                <div className="col-span-full">
                  <label htmlFor="cep" className="block text-sm font-medium leading-6 text-gray-900">
                    CEP
                  </label>
                  <div className="mt-2">
                    <input
                    type="text"
                    id="cep"
                    value={cep}
                    onChange={(e) => setCep(e.target.value.replace(/\D/g, ''))}
                    maxLength={8}
                    className="text-center block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="col-span-full">
                  <label htmlFor="estado" className="block text-sm font-medium leading-6 text-gray-900">
                    Estado
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="estado"
                      id="estado"
                      autoComplete="estado"
                      value={estado}
                      onChange={(e) => setEstado(e.target.value)}
                      className="text-center block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="col-span-full">
                  <label htmlFor="cidade" className="block text-sm font-medium leading-6 text-gray-900">
                    Cidade
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="cidade"
                      id="cidade"
                      autoComplete="cidade"
                      value={cidade}
                      onChange={(e) => setCidade(e.target.value)}
                      className="text-center block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="col-span-full">
                  <label htmlFor="bairro" className="block text-sm font-medium leading-6 text-gray-900">
                    Bairro
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="bairro"
                      id="bairro"
                      autoComplete="bairro"
                      value={bairro}
                      onChange={(e) => setBairro(e.target.value)}
                      className="text-center block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="col-span-full">
                  <label htmlFor="logradouro" className="block text-sm font-medium leading-6 text-gray-900">
                    Logradouro
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="logradouro"
                      id="logradouro"
                      autoComplete="logradouro"
                      value={logradouro}
                      onChange={(e) => setLogradouro(e.target.value)}
                      className="text-center block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="sm:col-span-2 sm:col-start-1">
                  <label htmlFor="numero" className="block text-sm font-medium leading-6 text-gray-900">
                    Numeral
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="numero"
                      id="number"
                      autoComplete="numero"
                      value={numeral}
                      className="text-center block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={(e) => setNumeral(e.target.value)}
                    />
                  </div>
                </div>

                <div className="sm:col-span-4">
                  <label htmlFor="complemento" className="block text-sm font-medium leading-6 text-gray-900">
                    Complemento
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="complemento"
                      id="complemento"
                      autoComplete="address-level1"
                      value={complemento}
                      className="text-center block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={(e) => setComplemento(e.target.value)}
                    />
                  </div>
                </div>
          </div>
        </div>
      </div>

      <div className="mt-6 flex items-center justify-end gap-x-6 pb-12">
        <Link to="/paciente" className="text-indigo-600 hover:text-indigo-900">
          Voltar
        </Link>
        <button
          type="button"
          className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={handleSubmit}
        >
          Salvar
        </button>
      </div>
    </form>

</div>

    );
}
