import { Button, Card, CardFooter, Typography } from "@material-tailwind/react";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  PlusIcon,
  TrashIcon,
  PencilIcon,
  MagnifyingGlassIcon
} from '@heroicons/react/24/outline'
import { PessoaContext } from "../../../../contexts/Pessoa/PessoaContext";
import { Tooltip } from "@mui/material";
import { AuthContext } from "../../../../contexts/Auth/AuthContext";
import DetailModal from "../../../../components/Utils/DetailModal";

const TABLE_HEAD = ["Matrícula","Auxiliar","Escolaridade","Email","Telefone","Jornadas","Ação"];

const ITEMS_PER_PAGE = 20;

interface Horario {
  entrada: string;
  saida: string;
}

interface HorariosSemana {
  segunda: Horario[];
  terca: Horario[];
  quarta: Horario[];
  quinta: Horario[];
  sexta: Horario[];
  sabado: Horario[];
  domingo: Horario[];
}

interface MonitorItem {
  id_monitor: string;
  nome: string;
  cpf: string;
  escolaridade: string;
   matricula: string;
  dt_inicio: string;
  dt_fim: string;
  email: string;
  telefone: string;
  horarios: HorariosSemana; // Aqui estamos tipando a propriedade horarios com a interface HorariosSemana
  permissao: {
    administrador: boolean;
    supervisor: boolean;
  };
}

interface DeleteItemParams {
  id_monitor: string;
  nome: string;
}

interface SortConfig {
  key: keyof MonitorItem;
  direction: 'ascending' | 'descending';
}

export function GerenciaMonitor() {
  const { monitorActions } = useContext(PessoaContext);
  const [tableData, setTableData] = useState<MonitorItem[]>([]);
  const [nomeFilter, setNomeFilter] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const [deleteItem, setDeleteItem] = useState<DeleteItemParams | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [, setSelectedItem] = useState<MonitorItem | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState<{ [key: string]: any }>({});
  const auth = useContext(AuthContext);
  const idUser = auth.user?.id_user;
  const IsAdministrador = auth.user?.level === 4;
  const IsSupervisor = auth.user?.supervisor;
  const IsTerapeuta = auth.user?.level === 3;
  const IsMonitor = auth.user?.level === 2;
  const IsEstagiario = auth.user?.level === 1;
  const IsAdmin = auth.user?.admin;
  const [sortConfig, setSortConfig] = useState<SortConfig>({ key: 'matricula', direction: 'ascending' });

  const handleSort = (key: keyof MonitorItem) => {
    let direction: 'ascending' | 'descending';

    if (sortConfig.key === key) {
      // Alterna a direção se a mesma coluna for clicada
      direction = sortConfig.direction === 'ascending' ? 'descending' : 'ascending';
    } else {
      // Define a direção como 'ascending' para novas colunas
      direction = 'ascending';
    }

    setSortConfig({ key, direction });
  };

  useEffect(() => {
    const fetchItemData = async () => {
      setIsLoading(true);
      let ItemData: MonitorItem[];
      try {
        ItemData = await monitorActions.fill();
        setTableData(ItemData);
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchItemData();
  }, [monitorActions]);

  useEffect(() => {
    if (nomeFilter !== '') {
      setCurrentPage(1); 
    }
  }, [nomeFilter]);
  

  const removeAccents = (str: string) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  const filteredData = Array.isArray(tableData) ? tableData.filter(({ nome }) => {
    if (nome) {
      const normalizedNome = removeAccents(nome.toString().toLowerCase());
      const normalizedFilter = removeAccents(nomeFilter.toLowerCase());
      const nomeMatches = nomeFilter ? normalizedNome.includes(normalizedFilter) : true;
      return nomeMatches;
    }
    return true;
  }) : [];

  const fieldMap: { [key: string]: keyof MonitorItem } = {
 
  "matrícula": "matricula",
  "monitor": "nome",
  
};
  
const sortedData = [...filteredData].sort((a, b) => {
    const keyA = fieldMap[sortConfig.key] || sortConfig.key;
  const aKey = a[keyA];
  const bKey = b[keyA];
  
  if (typeof aKey === 'string' && typeof bKey === 'string') {
    return sortConfig.direction === 'ascending' 
      ? aKey.localeCompare(bKey) 
      : bKey.localeCompare(aKey);
  }

  // Adiciona uma verificação para valores numéricos
  if (typeof aKey === 'number' && typeof bKey === 'number') {
    return sortConfig.direction === 'ascending' ? aKey - bKey : bKey - aKey;
  }

  return 0;
});

  
  const totalPages = Math.ceil(filteredData.length / ITEMS_PER_PAGE);

  const visibleData = sortedData.slice((currentPage - 1) * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE);

  

  const handleDeleteButtonClick = (DeleteItem:DeleteItemParams) => {
    setDeleteItem(DeleteItem);
    setConfirmDelete(true);
  };

  const handleButtonClick = () => {
    navigate('/auxiliar/add');
  };

  const handleEditButtonClick = (item: MonitorItem) => {
    setSelectedItem(item);
    navigate('/auxiliar/edit', { state: { selectedItem: item } });
  };


  const first = () => {
    if (currentPage > 1) {
      setCurrentPage(1);
    }
  };

  const ult = () => {
    if (currentPage < totalPages) {
      setCurrentPage(totalPages);
    }
  };

  const next = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const confirmDeleteItem = () => {
    if (deleteItem && idUser && IsAdministrador && IsAdministrador === true) {

      monitorActions.delete(String(deleteItem.id_monitor), idUser)
      .then(() => {

          setDeleteItem(null);
          setConfirmDelete(false);
          window.location.reload();
        })
        .catch((error) => {
          console.error('Erro ao deletar o item:', error);
        });
    } else {
             alert('Você não possui permissão, caso necessite realizar a operação entre em contato com o administrador para alterar as permissões.');

    }
  };

  
    const cancelDeleteItem = () => {
      setDeleteItem(null);
      setConfirmDelete(false);
    };

    const calculateTotalHours = (entrada: string, saida: string): number => {
      const entradaDate = new Date(`2000-01-01T${entrada}`);
      const saidaDate = new Date(`2000-01-01T${saida}`);
      const diff = saidaDate.getTime() - entradaDate.getTime();
      return diff / (1000 * 60 * 60); // Convertendo para horas
    };
  
    const formatHoursMinutes = (totalHours: number): string => {
      const hours = Math.floor(totalHours);
      const minutes = Math.round((totalHours - hours) * 60);
      return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    };

    const calculateTotalGeneralHours = (data: MonitorItem[]): number => {
      return data.reduce((total, monitor) => {
        const { horarios } = monitor;
        Object.values(horarios).forEach((horariosArray: Horario[]) => {
          horariosArray.forEach((horario: Horario) => {
            total += calculateTotalHours(horario.entrada, horario.saida);
          });
        });
        return total;
      }, 0);
    };

   let totalGeneralHours=0; // Exemplo com valor inicial

   const handleViewButtonClick = (item: MonitorItem) => {

    const horariosFormatados = Object.entries(item.horarios).map(([dia, horariosArray]) => {
      if (horariosArray.length === 0) {
        return null; // Ignorar dias sem horários
      }
  
      const horariosString = horariosArray.map((horario: Horario) => {
        const [entradaHora, entradaMinuto] = horario.entrada.split(':').map(Number);
        const [saidaHora, saidaMinuto] = horario.saida.split(':').map(Number);
  
        const entradaDate = new Date(0, 0, 0, entradaHora, entradaMinuto);
        const saidaDate = new Date(0, 0, 0, saidaHora, saidaMinuto);
  
        const diffInMilliseconds = saidaDate.getTime() - entradaDate.getTime();
        const hours = diffInMilliseconds / (1000 * 60 * 60);
  
        const entradaHoraFormatada = String(entradaHora).padStart(2, '0');
        const entradaMinutoFormatado = String(entradaMinuto).padStart(2, '0');
        const saidaHoraFormatada = String(saidaHora).padStart(2, '0');
        const saidaMinutoFormatado = String(saidaMinuto).padStart(2, '0');
  
        return `${entradaHoraFormatada}:${entradaMinutoFormatado} - ${saidaHoraFormatada}:${saidaMinutoFormatado} (${hours.toFixed(2)} horas)`;
      }).join(', ');
  
      return `${dia}: ${horariosString}`;
    }).filter(Boolean).join('\n'); // Filtrar nulos e unir as strings

    setModalData({
      "Nome": item.nome?item.nome:'',
      "Matrícula": item.matricula?item.matricula:'',
      "CPF": item.cpf?item.cpf:'',
      "Email": item.email?item.email:'',
      "Escolaridade": item.escolaridade?item.escolaridade:'',
      "Data de Início": item.dt_inicio?item.dt_inicio:'',
      "Data de Fim": item.dt_fim?item.dt_fim:'',
      "Horários": horariosFormatados
    });
    setIsModalOpen(true);
  };

  return (
    <div>
      <div className="p-4 mb-4 flex flex-col md:flex-row md:items-center md:space-x-4 bg-gray-100 rounded-lg">
        <Button 
          placeholder='' 
          variant="outlined" 
          size="sm"
          onClick={handleButtonClick}
          className={'p-2 my-2 focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-normal rounded-lg text-sm me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800'}
          >
          <PlusIcon className='w-6 h-6' />
        </Button>
        <div className="flex flex-col md:flex-row md:items-center space-y-2 md:space-y-0 md:space-x-2 my-4">
          <label htmlFor="monitorFilter" className="text-black-700 md:mt-0 mt-2">
            Auxiliar
          </label>
          <div className="relative">
            <input
              type="text"
              id="monitorFilter"
              value={nomeFilter}
              onChange={(e) => setNomeFilter(e.target.value)}
              className="border border-gray-300 pl-8 pr-2 py-2 rounded"
            />
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
          </div>
        </div>
        <div className="flex flex-wrap md:flex-nowrap items-center pl-24">
          <h2 className="text-3xl font-bold font-mono relative pr-4 italic text-transparent bg-clip-text bg-gradient-to-r to-green-600 from-blue-400 mb-4 md:mb-0">
            Registros
          </h2>
          <h2 className="text-3xl font-bold font-mono relative pr-4 italic text-transparent bg-clip-text bg-gradient-to-r to-blue-600 from-green-400 mb-4 md:mb-0">
            Auxiliares
          </h2>
        </div>
      </div>
      <Card placeholder='' className="h-full w-full overflow-auto">
        <table className="w-full min-w-max table-auto text-center">
           <thead>
            <tr>
              {TABLE_HEAD.map((head) => {
                const key = head.toLowerCase().replace(/\s+/g, '') as keyof MonitorItem;
                return (
                  <th key={head} className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 cursor-pointer" onClick={() => handleSort(key)}>
                    <Typography placeholder='' variant="small" color="blue-gray" className="font-normal leading-none opacity-70">
                      {head} {sortConfig.key === key ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : ''}
                    </Typography>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
          {isLoading ? (
              <tr>
                <td colSpan={TABLE_HEAD.length} className="p-4">Carregando...</td>
              </tr>
            ) : visibleData.map((item, index) => {
            // Verifica se o id_monitor é diferente do idUser
            if (item.id_monitor !== idUser) {
              return (
                <tr key={index} className={index % 2 === 0 ? "bg-gray-200" : "bg-white"}>
                  <td className="p-4 border-b border-blue-gray-100">
                    <Typography placeholder='' variant="small" color="blue-gray" className="font-normal">
                      {item.matricula ? item.matricula : ''}
                    </Typography>
                  </td>
            
                  <td className="p-4 border-b border-blue-gray-100">
                    <Typography placeholder='' variant="small" color="blue-gray" className="font-normal">
                      {item.nome ? item.nome : ''}
                    </Typography>
                  </td>
                  <td className="p-4 border-b border-blue-gray-100">
                    <Typography placeholder='' variant="small" color="blue-gray" className="font-normal">
                      {item.escolaridade ? item.escolaridade : ''}
                    </Typography>
                  </td>
                  <td className="p-4 border-b border-blue-gray-100">
                    <Typography placeholder='' variant="small" color="blue-gray" className="font-normal">
                      {item.email ? item.email : ''}
                    </Typography>
                  </td>
                  <td className="p-4 border-b border-blue-gray-100">
                    <Typography placeholder='' variant="small" color="blue-gray" className="font-normal">
                      {item.telefone ? item.telefone : ''}
                    </Typography>
                  </td>
                  <td className="p-4 border-b border-blue-gray-100">
                    {Object.entries(item.horarios).map(([dia, horariosArray]) => (
                      horariosArray.length > 0 && (
                        <div key={dia}>
                          <ul>
                            {horariosArray.map((horario: Horario, idx: number) => { // Adicionando tipagem explícita
                              const [entradaHora, entradaMinuto] = horario.entrada.split(':').map(Number);
                              const [saidaHora, saidaMinuto] = horario.saida.split(':').map(Number);

                              const entradaDate = new Date(0, 0, 0, entradaHora, entradaMinuto);
                              const saidaDate = new Date(0, 0, 0, saidaHora, saidaMinuto);

                              const diffInMilliseconds = saidaDate.getTime() - entradaDate.getTime();
                              const hours = diffInMilliseconds / (1000 * 60 * 60);

                              totalGeneralHours += hours;
                            })}
                          </ul>
                        </div>
                      )
                    ))}
                  <Typography placeholder='' variant="small" color="blue-gray" className="font-normal">
                    {totalGeneralHours.toFixed(2)} horas    <span style={{ display: 'none' }}>
                    {1===1 ? (totalGeneralHours = 0) : ' '}
                  </span>      
                  </Typography>
                  </td>
                  <td className="border-b border-blue-gray-100 p-2">
                <div className="flex space-x-2 justify-center">
                  <Tooltip title="Visualizar">
                    <Button 
                      placeholder=''
                      className="w-8 h-8 flex items-center justify-center p-1 bg-blue-500 hover:bg-blue-700 text-white rounded-full"
                      onClick={() => handleViewButtonClick(item)}
                    >
                      <MagnifyingGlassIcon className="h-5 w-5" />
                    </Button>
                  </Tooltip>
                  {IsAdmin === true || IsAdministrador === true || IsSupervisor === true? <Tooltip title="Atualizar Informações" placement="top">
                    <Typography
                      placeholder=''
                      variant="small"
                      as="button"
                      onClick={() => handleEditButtonClick(item)}
                      color="blue-gray"
                      className="w-8 h-8 flex items-center justify-center p-1 bg-green-500 hover:bg-green-700 text-white rounded-full"
                      >
                      <PencilIcon className="w-6 h-6" />
                    </Typography>
                  </Tooltip>:''}
                  {IsAdministrador === true? <Tooltip title="Deletar" placement="top">
                    <Button
                      placeholder=""
                      color="blue-gray"
                      className="w-8 h-8 flex items-center justify-center p-1 bg-red-500 hover:bg-red-700 text-white rounded-full"
                      onClick={() => handleDeleteButtonClick(item)}
                    >
                      <TrashIcon className="w-6 h-6" />
                    </Button>
                  </Tooltip>:''}
                </div>
              </td>
                </tr>
              );
            }
            // Se o id_monitor for igual ao idUser, não renderize esse item na tabela
            return null;
          })}
          </tbody>
          {confirmDelete && deleteItem && (
          <div className="fixed inset-0 z-10 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
            <div className="relative w-auto max-w-3xl mx-auto my-6">
              <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
                <div className="flex items-start justify-between p-5 border-b border-solid rounded-t border-blue-gray-200">
                  <h3 className="text-3xl font-semibold">Confirmar exclusão</h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={cancelDeleteItem}
                  >
                    <span className="text-black h-6 w-6 text-2xl block outline-none focus:outline-none">×</span>
                  </button>
                </div>
                <div className="relative p-6 flex-auto">
                  <p className="my-4 text-blue-gray-500 text-lg leading-relaxed">Tem certeza que deseja excluir o Auxiliar ID: {deleteItem.id_monitor}?</p>
                </div>
                <div className="flex items-center justify-end p-6 border-t border-solid rounded-b border-blue-gray-200">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={cancelDeleteItem}
                  >
                    Cancelar
                  </button>
                  <button
                    className="bg-red-700 text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={confirmDeleteItem}
                  >
                    Confirmar
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        </table>
      </Card>
      <CardFooter placeholder='' className="flex items-center justify-between border-t border-blue-gray-50 p-4">
        <Typography placeholder='' variant="small" color="blue-gray" className="font-normal">
        Página <strong className="text-blue-gray-900">{currentPage}</strong> de{" "}
              <strong className="text-blue-gray-900">{totalPages}</strong>
        </Typography>
        <div className="flex gap-2">
        <Button 
          placeholder='' 
          variant="outlined" 
          size="sm"
          onClick={first}
          disabled={currentPage === 1}
          className="p-2"
          >
            Primeira
          </Button>
          <Button 
          placeholder='' 
          variant="outlined" 
          size="sm"
          onClick={prev}
          disabled={currentPage === 1}
          className="p-2"
          >
            Anterior
          </Button>
          <Button 
          placeholder='' 
          variant="outlined" 
          size="sm" 
          onClick={next}
          disabled={currentPage === totalPages}
          className="p-2"
          >
            Próximo
          </Button>
          <Button 
          placeholder='' 
          variant="outlined" 
          size="sm"
          onClick={ult}
          disabled={currentPage === totalPages}
          className="p-2"
          >
            Última
          </Button>
        </div>
      </CardFooter>
      <DetailModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        data={modalData}
      />
    </div>
  );
}
